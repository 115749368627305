import filter from 'lodash/filter';

export const selectCategoriesRoot = state => state.categories;
export const selectCategories = state => state.categories.flattenData;

export const selectCategoriesData = state => state.categories.data;

export const selectHasCategories = state => !!state.categories.data.length;

export const selectSubCategories = state =>
  filter(state.categories.flattenData, item => !!item.image);

export const selectIsFetchingCategories = state => state.categories.isFetchingCategories;

export const selectCategoryDetails = id => state => state.categories.flattenData?.[id];

export const selectCategoryBySlug = slug => state =>
  Object.entries(state.categories.flattenData).find(([, category]) => category.slug === slug);

export const selectCategoryIcons = state => state.categories.icons;
