import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg
      width={22}
      height={2}
      viewBox="0 0 22 2"
      fill="#000000"
      {...props}
    >
      <rect
        x="0"
        y="0"
        width="22"
        height="2"
      />
    </svg>
  );
}

export default SvgComponent;
