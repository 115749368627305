import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg
      width={14}
      height={14}
      viewBox="0 0 14 14"
      {...props}
    >
      <path d="M5.727.174v2h4.692L.009 12.582l1.415 1.414 10.41-10.41-.002 4.692h2V.174z" />
    </svg>
  );
}

export default SvgComponent;
