import {
  COMPANY_MENU_IDENTIFIER,
  FOR_CLIENTS_MENU_IDENTIFIER,
  LEGAL_MENU_IDENTIFIER,
  MAIN_MENU_IDENTIFIER,
} from '@common/constants/menus';

export const selectCorrespondenceLanguage = state => state.customerDetail.data.customer.language;

export const selectHasAllMenus = state =>
  !!state.menus[MAIN_MENU_IDENTIFIER] &&
  !!state.menus[COMPANY_MENU_IDENTIFIER] &&
  !!state.menus[LEGAL_MENU_IDENTIFIER] &&
  !!state.menus[FOR_CLIENTS_MENU_IDENTIFIER];

// eslint-disable-next-line no-underscore-dangle
export const selectPeristRehydrated = state => state._persist?.rehydrated;
