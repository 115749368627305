import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      {...props}
    >
      <defs>
        <path
          d="M5 0a8 8 0 110 16A8 8 0 015 0zm3.364 5L3.545 9.746l-1.909-1.88L1 8.493 3.545 11 9 5.627 8.364 5z"
          id="prefix__a"
        />
      </defs>
      <use
        xlinkHref="#prefix__a"
        transform="translate(3)"
      />
    </svg>
  );
}

export default SvgComponent;
