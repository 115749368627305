const UrlPattern = require('url-pattern');
const qs = require('qs');
const getConfig = require('next/config').default;

const { LANGUAGE_DE, LANGUAGE_FR } = require('@common/constants/languages');
const { frenchUrlsRouteMap, germanUrlsRouteMap } = require('@common/routes');

const { findRouteByPath } = require('./findRouteByPath');

const { FRONTEND_BASE_URL: defaultDomain } = getConfig()?.publicRuntimeConfig || {
  FRONTEND_BASE_URL: process.env.FRONTEND_BASE_URL,
};

const getPathUrl = (path, domain = defaultDomain) => {
  if (domain.slice(-1) === '/') {
    return `${domain.slice(0, -1)}${path}`;
  }

  return `${domain}${path}`;
};

function getUrlsMapByLanguage(language) {
  switch (language) {
    case LANGUAGE_FR:
      return frenchUrlsRouteMap;
    case LANGUAGE_DE:
    default: {
      return germanUrlsRouteMap;
    }
  }
}

function getRouteUrl(language, route, params = {}) {
  const urlsMap = getUrlsMapByLanguage(language);
  let routePath;

  if (!urlsMap[route]) {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.info(`Missing translated url for route: ${route} in language: ${language}`);
    }

    routePath = route;
  } else {
    routePath = urlsMap[route](params);
  }

  return `/${language}${routePath}`;
}

const URL_SEGMENTS = new UrlPattern(
  '(http(s)\\://)(:subdomain.)(:domain.)(:tld)(\\::port)/:lang(/*)'
);

const getLinkDataFromUrl = (url = '') => {
  const urlSegments = URL_SEGMENTS.match(url);

  if (!urlSegments) {
    return null;
  }

  const { lang, _: pathWithQuery = '' } = urlSegments;

  const [path, query] = `/${pathWithQuery}`.split('?');
  const queryFromUrl = qs.parse(query);

  const { pathname, pattern } = findRouteByPath(path, lang);

  return pathname ?
    {
      as: `/${lang}${path}`,
      urlObject: {
        pathname,
        query: {
          ...queryFromUrl,
          ...pattern.match(path),
        },
      },
    } :
    null;
};

module.exports = {
  getPathUrl,
  getRouteUrl,
  getLinkDataFromUrl,
};
