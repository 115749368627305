import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg
      width={20}
      height={23}
      viewBox="0 0 20 23"
      {...props}
    >
      <path d="M12.6 3.6L6.7 9.4l-.1.1-.8.8-.8.8-.1.3-.8 2.7-.6 2 2-.6 2.7-.8.3-.1.8-.8.1-.1.8-.8L16 7.2v13.3c0 1.1-.9 2-2 2H2c-1.1 0-2-.9-2-2V5.6c0-1.1.9-2 2-2h10.6zM6.7 11l2 1.9-.6.6-2.7.8.8-2.7.5-.6zM18 0c.1 0 .3.1.4.2l1.2 1.2c.2.2.2.5 0 .7l-10.1 10-2-1.9L17.6.2c.2-.1.3-.2.4-.2z" />
    </svg>
  );
}

export default SvgComponent;
