import React from 'react';

export function SvgComponent(props) {
  return (
    <svg
      width={512}
      height={512}
      viewBox="0 0 512 512"
      {...props}
    >
      <mask id="a">
        <circle
          cx="256"
          cy="256"
          r="256"
          fill="#fff"
        />
      </mask>
      <g mask="url(#a)">
        <path
          fill="#ffda44"
          d="M0 345l256.7-25.5L512 345v167H0z"
        />
        <path
          fill="#d80027"
          d="M0 167l255-23 257 23v178H0z"
        />
        <path
          fill="#333"
          d="M0 0h512v167H0z"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
