import PropTypes from 'prop-types';
import React from 'react';

import { Cross } from '@common/components/Icons';

import { CrossButton } from './CloseButton.styled';

export const CloseButton = ({
  onClick, className, buttonId, Icon, ...props
}) => (
  <CrossButton
    onClick={onClick}
    className={className}
    type="button"
    buttonId={buttonId}
    {...props}
  >
    <Icon />
  </CrossButton>
);

CloseButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  buttonId: PropTypes.string,
  Icon: PropTypes.elementType,
};

CloseButton.defaultProps = {
  className: undefined,
  onClick: undefined,
  buttonId: undefined,
  Icon: Cross,
};

export default CloseButton;
