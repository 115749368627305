import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg
      viewBox="0 0 24 22"
      width="24"
      height="22"
      {...props}
    >
      <polygon points="12 3.4106e-13 9.1677 8.4034 0 8.4034 7.4162 13.597 4.5838 22 12 16.807 19.415 22 16.584 13.597 24 8.4034 14.832 8.4034" />
    </svg>
  );
}

export default SvgComponent;
