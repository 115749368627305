import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      {...props}
    >
      <path d="M1.66089353,13.5897681 L4.41014185,16.3391515 L0,18 L1.66089353,13.5897681 Z M12.5338353,2.47566053 L15.524335,5.46616023 L5.4015009,15.5889943 L2.41100119,12.5984946 L12.5338353,2.47566053 Z M15.557054,0 C15.9221049,0 16.2169277,0.112319872 16.4415674,0.336959615 L17.6630404,1.55843259 C17.8876801,1.78307234 18,2.07789512 18,2.44294596 C18,2.80799679 17.8876801,3.10281958 17.6630404,3.32745932 L16.4415674,4.5489323 L13.4510677,1.55843259 L14.6725407,0.336959615 C14.8971804,0.112319872 15.1920032,0 15.557054,0 Z" />
    </svg>
  );
}

export default SvgComponent;
