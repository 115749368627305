import React from 'react';
import PropTypes from 'prop-types';

import Alert from '@common/components/Alert';

const AlertMessages = React.forwardRef(({ errorMessage, successMessage, onAlertClose }, ref) => (
  <React.Fragment>
    {errorMessage && (
      <Alert
        variant="error"
        onClose={() => onAlertClose('friendlyError')}
        ref={ref}
      >
        {errorMessage}
      </Alert>
    )}
    {successMessage && (
      <Alert
        variant="success"
        onClose={() => onAlertClose('friendlySuccess')}
        ref={ref}
      >
        {successMessage}
      </Alert>
    )}
  </React.Fragment>
));

AlertMessages.propTypes = {
  errorMessage: PropTypes.string,
  successMessage: PropTypes.string,
  onAlertClose: PropTypes.func.isRequired,
};

AlertMessages.defaultProps = {
  errorMessage: null,
  successMessage: null,
};

export default AlertMessages;
