export const FETCH_ALL_ACTIVITIES_REQUEST = 'FETCH_ALL_ACTIVITIES_REQUEST';
export const FETCH_ALL_ACTIVITIES_SUCCESS = 'FETCH_ALL_ACTIVITIES_SUCCESS';
export const FETCH_ALL_ACTIVITIES_FAILURE = 'FETCH_ALL_ACTIVITIES_FAILURE';

export const FETCH_PURCHASE_ACTIVITIES_REQUEST = 'FETCH_PURCHASE_ACTIVITIES_REQUEST';
export const FETCH_PURCHASE_ACTIVITIES_SUCCESS = 'FETCH_PURCHASE_ACTIVITIES_SUCCESS';
export const FETCH_PURCHASE_ACTIVITIES_FAILURE = 'FETCH_PURCHASE_ACTIVITIES_FAILURE';

export const FETCH_TRANSACTION_DETAILS_REQUEST = 'FETCH_TRANSACTION_DETAILS_REQUEST';
export const FETCH_TRANSACTION_DETAILS_SUCCESS = 'FETCH_TRANSACTION_DETAILS_SUCCESS';
export const FETCH_TRANSACTION_DETAILS_FAILURE = 'FETCH_TRANSACTION_DETAILS_FAILURE';

export const fetchAllActivitiesRequest = () => ({
  type: FETCH_ALL_ACTIVITIES_REQUEST,
});

export const fetchAllActivitiesSuccess = payload => ({
  payload,
  type: FETCH_ALL_ACTIVITIES_SUCCESS,
});

export const fetchAllActivitiesFailure = () => ({
  type: FETCH_ALL_ACTIVITIES_FAILURE,
});

export const fetchPurchaseActivitiesRequest = () => ({
  type: FETCH_PURCHASE_ACTIVITIES_REQUEST,
});

export const fetchPurchaseActivitiesSuccess = payload => ({
  payload,
  type: FETCH_PURCHASE_ACTIVITIES_SUCCESS,
});

export const fetchPurchaseActivitiesFailure = () => ({
  type: FETCH_PURCHASE_ACTIVITIES_FAILURE,
});

export const fetchTransactionDetailsRequest = id => ({
  id,
  type: FETCH_TRANSACTION_DETAILS_REQUEST,
});

export const fetchTransactionDetailsSuccess = (id, payload) => ({
  id,
  payload,
  type: FETCH_TRANSACTION_DETAILS_SUCCESS,
});

export const fetchTransactionDetailsFailure = id => ({
  id,
  type: FETCH_TRANSACTION_DETAILS_FAILURE,
});
