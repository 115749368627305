import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg
      width={16}
      height={21}
      viewBox="0 0 16 21"
      {...props}
    >
      <path d="M1.1 18.7c0 1.3 1 2.3 2.3 2.3h9.1c1.3 0 2.3-1 2.3-2.3v-14H1.1v14zM16 1.2h-4L10.9 0H5.2L4 1.2H0v2.3h16V1.2z" />
    </svg>
  );
}

export default SvgComponent;
