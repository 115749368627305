import { css } from 'styled-components';
import get from 'lodash/get';

import { breakpoints } from '@themes/main';

export const getThemePath = path => ({ theme }) => get(theme, path);

export const getThemeColor = color => getThemePath(`colors[${color}]`);

export const getThemeColorWithError = (color, errorColor, errorProp = 'hasError') => props =>
  (props[errorProp] ? getThemeColor(errorColor)(props) : getThemeColor(color)(props));

export const getThemeTransition = (
  property,
  speed = 'default',
  easing = 'default'
) => props => `${property}
  ${getThemePath(['transitions', 'speeds', speed])(props)}
  ${getThemePath(['transitions', 'easings', easing])(props)}`;

export const getGridBase = (columns = 12, noGap = false) => css`
  display: grid;
  grid-template-columns: repeat(${columns}, 1fr);
  ${({ theme }) => !noGap && theme.getFluidSpacing('grid-column-gap', 'widthBased', 1)}
`;

export const centeredGridColumns = (columns, rootColumns = 12) =>
  `${(rootColumns - columns) / 2 + 1} / span ${columns}`;

export const breakpointQuery = breakpoints.reduce((accumulator, br) => {
  const breakpoint = br * 10;

  accumulator[`from${breakpoint}up`] = `(min-width: ${breakpoint / 16}em)`;
  accumulator[`from${breakpoint}down`] = `(max-width: ${breakpoint / 16 - 0.02}em)`;

  return accumulator;
}, {});

export const media = Object.entries(breakpointQuery).reduce((accumulator, [key, query]) => {
  accumulator[key] = (...args) => css`
    @media ${query} {
      ${css(...args)};
    }
  `;

  return accumulator;
}, {});

export const getTypographyStyle = variant => css`
  ${({ theme }) => theme.getFluidFont(variant)};
  font-weight: ${({ theme }) => theme.fontSizeWeightMap[variant]};
  line-height: ${({ theme }) => theme.fontSizeLineHeightMap[variant]};
`;

export const hexToRgba = (hex, alpha) => {
  const r = parseInt(hex.substring(1, 3), 16);
  const g = parseInt(hex.substring(3, 5), 16);
  const b = parseInt(hex.substring(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
