// Operations on products in the basket
export const BASKET_ADD_PRODUCT = 'BASKET_ADD_PRODUCT';

export const addProductToBasket = payload => ({
  payload,
  type: BASKET_ADD_PRODUCT,
});

export const BASKET_REMOVE_PRODUCT = 'BASKET_REMOVE_PRODUCT';

export const removeProductFromBasket = payload => ({
  payload,
  type: BASKET_REMOVE_PRODUCT,
});

export const BASKET_UPDATE_PRODUCT = 'BASKET_UPDATE_PRODUCT';

export const updateProductInBasket = payload => ({
  payload,
  type: BASKET_UPDATE_PRODUCT,
});

export const CLEAR_BASKET = 'CLEAR_BASKET';

export const clearBasket = () => ({
  type: CLEAR_BASKET,
});

// Calculating the basket
export const GET_BASKET_CALCULATIONS_REQUEST = 'GET_BASKET_CALCULATIONS_REQUEST';
export const GET_BASKET_CALCULATIONS_SUCCESS = 'GET_BASKET_CALCULATIONS_SUCCESS';
export const GET_BASKET_CALCULATIONS_FAILURE = 'GET_BASKET_CALCULATIONS_FAILURE';

export const getBasketCalculationsRequest = () => ({
  type: GET_BASKET_CALCULATIONS_REQUEST,
});

export const getBasketCalculationsSuccess = payload => ({
  payload,
  type: GET_BASKET_CALCULATIONS_SUCCESS,
});

export const getBasketCalculationsFailure = payload => ({
  payload,
  type: GET_BASKET_CALCULATIONS_FAILURE,
});

// Payment methods
export const GET_BASKET_PAYMENT_METHODS_REQUEST = 'GET_BASKET_PAYMENT_METHODS_REQUEST';
export const GET_BASKET_PAYMENT_METHODS_FAILURE = 'GET_BASKET_PAYMENT_METHODS_FAILURE';
export const GET_BASKET_PAYMENT_METHODS_SUCCESS = 'GET_BASKET_PAYMENT_METHODS_SUCCESS';

export const getBasketPaymentMethodsRequest = () => ({
  type: GET_BASKET_PAYMENT_METHODS_REQUEST,
});

export const getBasketPaymentMethodsSuccess = payload => ({
  payload,
  type: GET_BASKET_PAYMENT_METHODS_SUCCESS,
});

export const getBasketPaymentMethodsFailure = payload => ({
  payload,
  type: GET_BASKET_PAYMENT_METHODS_FAILURE,
});

export const OPEN_ADD_TO_BASKET_TOOLTIP = 'OPEN_ADD_TO_BASKET_TOOLTIP';
export const CLOSE_ADD_TO_BASKET_TOOLTIP = 'CLOSE_ADD_TO_BASKET_TOOLTIP';

export const openAddToBasketTooltip = () => ({
  type: OPEN_ADD_TO_BASKET_TOOLTIP,
});

export const closeAddToBasketTooltip = () => ({
  type: CLOSE_ADD_TO_BASKET_TOOLTIP,
});

// Submit order
export const SUBMIT_ORDER_REQUEST = 'SUBMIT_ORDER_REQUEST';
export const SUBMIT_ORDER_SUCCESS = 'SUBMIT_ORDER_SUCCESS';
export const SUBMIT_ORDER_FAILURE = 'SUBMIT_ORDER_FAILURE';

export const submitOrderRequest = () => ({
  type: SUBMIT_ORDER_REQUEST,
});

export const submitOrderSuccess = payload => ({
  payload,
  type: SUBMIT_ORDER_SUCCESS,
});

export const submitOrderFailure = payload => ({
  payload,
  type: SUBMIT_ORDER_FAILURE,
});

export const CLEAR_BASKET_ORDER = 'CLEAR_BASKET_ORDER';

export const clearBasketOrder = () => ({
  type: CLEAR_BASKET_ORDER,
});
