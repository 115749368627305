import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg
      width={22}
      height={15}
      viewBox="0 0 22 15"
      {...props}
    >
      <defs>
        <path
          d="M11 17.5a5 5 0 110-10 5 5 0 010 10M11 5c5 0 9.3 3.1 11 7.5a11.8 11.8 0 01-22 0C1.7 8.1 6 5 11 5zm0 4.5a3 3 0 100 6 3 3 0 000-6z"
          id="prefix__b"
        />
      </defs>
      <use
        xlinkHref="#prefix__b"
        transform="translate(0 -5)"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgComponent;
