const { GIFT_CARDS_VIEW_MODE_DE, GIFT_CARDS_VIEW_MODE_FR } = require('@common/constants/giftCards');

exports.HOME = '/';

// Register
exports.PRE_REGISTER = '/pre-register';
exports.REGISTER = '/register';
exports.REGISTER_SUCCESS = '/register-success';
exports.EMAIL_SENT = '/email-sent';
exports.ACTIVATION_RESEND = '/auth/activation-resend';
exports.ACTIVATE_ACCOUNT = '/auth/activation';
exports.CREATE_PROFILE = '/create-profile';

// Login
exports.PRE_LOGIN = '/pre-login';
exports.LOGIN = '/login';
exports.VIP_CARD_LOGIN = '/vip-card-login';
exports.PATIENCE = '/auth/card/patience';

// Forgot password
exports.FORGOT_PASSWORD = '/auth/password/forgot-password';
exports.PASSWORD_RESET_SENT = '/auth/password/reset-sent';
exports.PASSWORD_RESET_CHANGE = '/auth/password/change';

// CMS
exports.CMS_PAGE = '/[...slug]';

// Products
exports.PRODUCT = '/products/[slug]/id/[id]';
exports.PRODUCT_AVAILABILITY = '/products/[slug]/id/[id]/#availability';
exports.PRODUCTS_CATEGORY = '/products/categories/[slug]/[id]';
exports.PRODUCTS_SALE = '/products/sale';
exports.PRODUCTS_NEW = '/products/new';
exports.PRODUCTS_OFFER = '/products/offer';
exports.PRODUCTS_ALL = '/products';
exports.PRODUCTS_CATCH_ALL = '/products/[...slug]';

// Stores
exports.STORE_FINDER = '/stores/finder/[type]';
exports.STORE = '/stores/[slug]/[store]';

// Jobs
exports.JOBS_JOB_OFFER_DETAILS = '/jobs/job-offer/[slug]/[id]';
exports.JOBS_OPERATION_AREA_DETAILS = '/jobs/[slug]/[id]';
exports.JOBS_SPONTANEOUS_APPLICATION = '/jobs/spontaneous-application';
exports.JOBS_APPLICATION_SENT = '/jobs/application-sent';

// Newsletter
exports.NEWSLETTER_REGISTRATION = '/newsletter/registration';
exports.NEWSLETTER_REGISTRATION_CONFIRM = '/newsletter/registration/confirm';
exports.NEWSLETTER_UNREGISTER = '/newsletter/unregister';

// Vip
exports.VIP_HOME = '/vip';
exports.ACCOUNT_OPTIMIZATION = '/vip/account-optimization';
exports.VIP_ACTIVITIES = '/vip/activities/[type]';
exports.VIP_ACTIVITIES_ALL = '/vip/activities/all';
exports.VIP_ACTIVITIES_PURCHASES = '/vip/activities/purchases';
exports.VIP_GOODIES = '/vip/goodies';
exports.VIP_SETTINGS = '/vip/settings';
exports.VIP_SETTINGS_ACCOUNT_DELETION = '/vip/settings/deletion';
exports.VIP_SETTINGS_AVATAR = '/vip/settings/avatar';
exports.VIP_SETTINGS_CHANGE_EMAIL = '/vip/settings/change-email';
exports.VIP_SETTINGS_CHANGE_EMAIL_CONFIRM = '/vip/settings/change-email/confirm';
exports.VIP_SETTINGS_CHANGE_EMAIL_TOKEN = '/vip/settings/change-email-confirm';
exports.VIP_SETTINGS_CHANGE_PASSWORD = '/vip/settings/change-password';
exports.VIP_SETTINGS_NOTIFICATIONS = '/vip/settings/notifications';
exports.VIP_SETTINGS_PROFILE_DETAILS = '/vip/settings/profile';
exports.VIP_SINGLE_PURCHASE = '/vip/purchase/[id]';
exports.VIP_VOUCHERS = '/vip/vouchers';
exports.VIP_INVALID_CARD = '/vip-invalid-card';
exports.VIP_MOBILE = '/vip/mobile';
exports.VIP_CARD_NOT_YET_ACTIVATED = '/vip-card-inactive';
exports.VIP_CARD_ALREADY_ACTIVATED = '/vip-card-active';
exports.VIP_CARD_INVALID_EMAIL = '/vip-card-invalid-email';

// Mobile (cellular)
exports.MOBILE_MAIN_PAGE = '/mobile';
exports.MOBILE_ORDER_FORM = '/mobile/order/[plan]';
exports.MOBILE_ORDER_SUCCESS = '/mobile/order/success';
exports.MOBILE_PLAN_DETAILS = '/mobile/plan/[slug]';

// Games
exports.GAME_WOF = '/games/wof-splash';
exports.GAME_TROPHY_HUNT = '/games/trophy-hunt-splash';
exports.GAME_ADVENTCALENDAR = '/games/adventcalendar-splash';
exports.GAME_BAG = '/games/bag-challenge-splash';
exports.GAME_WOF_PLAY = '/games/wof-web';
exports.GAME_WOF_PLAYED = '/games/wof-played';
exports.GAME_WOF_UNAVAILABLE = '/games/wof-unavailable';

// Moment / Shop event
exports.SHOP_EVENT = '/shop-event';
exports.SHOP_EVENT_PRODUCT = '/shop-event/products/[slug]/[id]';

// Checkout
exports.CHECKOUT = '/checkout';
exports.CHECKOUT_SUCCESS = '/checkout/success';
exports.CHECKOUT_GATEWAY = '/checkout/gateway/[paymentStatus]';

// Gift Cards
exports.GIFT_CARDS_VIEW = '/gift-cards/[view]';
exports.GIFT_CARDS_BALANCE_CHECK = '/gift-cards/balance/check';
exports.GIFT_CARDS_PURCHASE_SUMMARY = '/gift-cards/purchase/summary';
exports.GIFT_CARDS_PURCHASE_SUCCESS = '/gift-cards/purchase/success';
exports.GIFT_CARDS_PURCHASE_GATEWAY = '/gift-cards/purchase/gateway/[paymentStatus]';

// Misc
exports.BASKET = '/basket';
exports.NOT_FOUND = '/404';
exports.AUTH_TERMS_AND_CONDITIONS = '/auth/terms-and-conditions';
exports.VERIFY_ADDRESS = '/verify-address/[token]';
exports.FAVORITES = '/favorites';
exports.GOODIES_DETAILS = '/goodies/[slug]/[id]';
exports.CMS_LOOKBOOK_PAGE = '/lookbooks/[...slug]';
exports.DELIVERY_CONDITIONS = '/delivery-conditions';

// URLs
exports.JOBS_HOME_URL = {
  de: () => '/arbeiten-bei-chicoree/',
  fr: () => '/travailler-pour-chicoree/',
};

exports.SCREEN_NAME_UNKNOWN = 'unknown';

exports.FIXED_ROUTES = [
  exports.STORE_FINDER,
  exports.AUTH_TERMS_AND_CONDITIONS,
  exports.FAVORITES,
  exports.JOBS_SPONTANEOUS_APPLICATION,
  exports.PRODUCTS_OFFER,
  exports.PRODUCTS_SALE,
  exports.PRODUCTS_NEW,
  exports.PRODUCTS_ALL,
  exports.PRODUCTS_CATCH_ALL,
  exports.MOBILE_MAIN_PAGE,
  exports.GAME_WOF,
  exports.GAME_TROPHY_HUNT,
  exports.GAME_ADVENTCALENDAR,
  exports.GAME_BAG,
  exports.SHOP_EVENT,
  exports.GIFT_CARDS_VIEW,
];

exports.MASTER_SLUG_VIEW_NAME_MAP = {
  [exports.HOME]: 'Homepage',
};

exports.ROUTES_FOR_LOGGED_IN = [
  exports.ACCOUNT_OPTIMIZATION,
  exports.CREATE_PROFILE,
  exports.AUTH_TERMS_AND_CONDITIONS,
  exports.VIP_ACTIVITIES, // TODO
  exports.VIP_GOODIES,
  exports.VIP_HOME,
  exports.VIP_SETTINGS,
  exports.VIP_SETTINGS_ACCOUNT_DELETION,
  exports.VIP_SETTINGS_AVATAR,
  exports.VIP_SETTINGS_CHANGE_PASSWORD,
  exports.VIP_SETTINGS_NOTIFICATIONS,
  exports.VIP_SETTINGS_PROFILE_DETAILS,
  exports.VIP_SETTINGS_CHANGE_EMAIL,
  exports.VIP_SETTINGS_CHANGE_EMAIL_TOKEN,
  exports.VIP_SETTINGS_CHANGE_EMAIL_CONFIRM,
  exports.VIP_SINGLE_PURCHASE,
  exports.VIP_VOUCHERS,
  exports.VIP_MOBILE,
];

exports.NOT_CACHEABLE_ROUTES = [
  ...exports.ROUTES_FOR_LOGGED_IN,
  exports.NEWSLETTER_UNREGISTER,
  exports.FAVORITES,
  exports.GOODIES_DETAILS,
  exports.PRODUCTS_ALL,
  exports.PRODUCTS_CATCH_ALL,
  exports.PRODUCTS_SALE,
  exports.PRODUCTS_NEW,
  exports.PRODUCTS_OFFER,
  exports.PRODUCTS_CATEGORY,
  exports.BASKET,
];

exports.germanUrlsRouteMap = {
  [exports.ACCOUNT_OPTIMIZATION]: () => '/vip/account-optimization/',
  [exports.ACTIVATE_ACCOUNT]: () => '/auth/activation/',
  [exports.ACTIVATION_RESEND]: () => '/auth/activation/resend/',
  [exports.PRODUCTS_ALL]: () => '/frauen-kleidung/',
  [exports.PRODUCTS_CATCH_ALL]: query => `/frauen-kleidung/${query.slug}`,
  [exports.AUTH_TERMS_AND_CONDITIONS]: () => '/auth/agb/',
  [exports.CMS_LOOKBOOK_PAGE]: query => `/lookbooks/${query.slug}/`,
  [exports.CMS_PAGE]: query => `/${query.slug}/`,
  [exports.CREATE_PROFILE]: () => '/auth/customer/create/',
  [exports.EMAIL_SENT]: () => '/auth/activation/email-sent-confirm/',
  [exports.FAVORITES]: () => '/vip/favorites/',
  [exports.FORGOT_PASSWORD]: () => '/auth/password/request-reset/',
  [exports.GOODIES_DETAILS]: query => `/goodies/${query.slug}/id/${query.id}/`,
  [exports.HOME]: () => '/',
  [exports.JOBS_APPLICATION_SENT]: () => '/arbeiten-bei-chicoree/application-sent/',
  [exports.JOBS_JOB_OFFER_DETAILS]: query => `/offene-stellen/${query.slug}/id/${query.id}/`,
  [exports.JOBS_OPERATION_AREA_DETAILS]: query =>
    `/arbeiten-bei-chicoree/${query.slug}/id/${query.id}/`,
  [exports.JOBS_SPONTANEOUS_APPLICATION]: () => '/arbeiten-bei-chicoree/spontanbewerbung/',
  [exports.LOGIN]: () => '/auth/login/email/',
  [exports.NEWSLETTER_REGISTRATION]: () => '/newsletter/registration/',
  [exports.NEWSLETTER_REGISTRATION_CONFIRM]: () => '/newsletter/registration/confirm/',
  [exports.NEWSLETTER_UNREGISTER]: () => '/newsletter/unregister/',
  [exports.NOT_FOUND]: () => '/not-found/',
  [exports.PASSWORD_RESET_CHANGE]: () => '/auth/password/reset/',
  [exports.PASSWORD_RESET_SENT]: () => '/auth/password/request-reset/confirm/',
  [exports.PATIENCE]: () => '/auth/login/card/patience/',
  [exports.PRE_LOGIN]: () => '/auth/login/overview/',
  [exports.PRE_REGISTER]: () => '/auth/registration/overview/',
  [exports.PRODUCT]: query => `/frauen-kleidung/${query.slug}/id/${query.id}/`,
  [exports.PRODUCT_AVAILABILITY]: query =>
    `/frauen-kleidung/${query.slug}/id/${query.id}/#availability`,
  [exports.PRODUCTS_CATEGORY]: query => `/frauen-kleidung/kategorie/${query.slug}/id/${query.id}/`,
  [exports.PRODUCTS_NEW]: () => '/frauen-kleidung/neuheiten/',
  [exports.PRODUCTS_OFFER]: () => '/frauen-kleidung/angebote/',
  [exports.PRODUCTS_SALE]: () => '/frauen-kleidung/sale/',
  [exports.REGISTER]: () => '/auth/registration/',
  // TODO translate url
  [exports.REGISTER_SUCCESS]: () => '/auth/registration/success/',
  [exports.STORE]: query => `/filialen/${query.slug}/id/${query.store}/`,
  [exports.STORE_FINDER]: query => `/filialen/${query.type || 'alle'}/`,
  [exports.VERIFY_ADDRESS]: query => `/verify-address/${query.token}/`,
  [exports.VIP_ACTIVITIES]: query => `/vip/activities/${query.type}/`,
  [exports.VIP_ACTIVITIES_ALL]: () => '/vip/activities/all/',
  [exports.VIP_ACTIVITIES_PURCHASES]: () => '/vip/activities/purchase/',
  [exports.VIP_CARD_LOGIN]: () => '/auth/login/card/',
  [exports.VIP_GOODIES]: () => '/vip/goodies/',
  [exports.VIP_HOME]: () => '/vip/',
  [exports.VIP_SETTINGS]: () => '/vip/settings/',
  [exports.VIP_SETTINGS_ACCOUNT_DELETION]: () => '/vip/settings/account-deletion/',
  [exports.VIP_SETTINGS_AVATAR]: () => '/vip/settings/avatar/',
  [exports.VIP_SETTINGS_CHANGE_EMAIL]: () => '/vip/settings/change-email/',
  [exports.VIP_SETTINGS_CHANGE_EMAIL_CONFIRM]: () => '/vip/settings/change-email/confirm/',
  [exports.VIP_SETTINGS_CHANGE_EMAIL_TOKEN]: () => '/vip/settings/change-email-confirm/',
  [exports.VIP_SETTINGS_CHANGE_PASSWORD]: () => '/vip/settings/change-password/',
  [exports.VIP_SETTINGS_NOTIFICATIONS]: () => '/vip/settings/notifications/',
  [exports.VIP_SETTINGS_PROFILE_DETAILS]: () => '/vip/settings/profile/',
  [exports.VIP_SINGLE_PURCHASE]: query => `/vip/purchase/${query.id}/`,
  [exports.VIP_VOUCHERS]: () => '/vip/vouchers/',
  [exports.VIP_MOBILE]: () => '/vip/mobile/',
  [exports.VIP_INVALID_CARD]: () => '/auth/login/card/unavailable-for-signup/',
  [exports.VIP_CARD_NOT_YET_ACTIVATED]: () => '/auth/login/card/not-activated/',
  [exports.VIP_CARD_INVALID_EMAIL]: () => '/auth/login/card/invalid-email/',
  [exports.VIP_CARD_ALREADY_ACTIVATED]: () => '/auth/login/card/already-activated/',
  // TODO translate urls
  [exports.MOBILE_ORDER_FORM]: query => `/mobile/order/${query.plan}/`,
  [exports.MOBILE_ORDER_SUCCESS]: () => '/mobile/order/success/',
  [exports.MOBILE_PLAN_DETAILS]: query => `/mobile/plan/${query.slug}/`,
  [exports.MOBILE_MAIN_PAGE]: () => '/mobile/',
  [exports.GAME_WOF]: () => '/wof-splash/',
  [exports.GAME_WOF_PLAY]: query => `/wof-web?token=${query.token}`,
  [exports.GAME_WOF_PLAYED]: () => '/wof-played/',
  [exports.GAME_WOF_UNAVAILABLE]: () => '/wof-unavailable/',
  [exports.GAME_BAG]: () => '/bag-challenge-splash/',
  [exports.GAME_TROPHY_HUNT]: () => '/trophy-hunt-splash/',
  [exports.GAME_ADVENTCALENDAR]: () => '/adventcalendar-splash/',
  [exports.SHOP_EVENT]: () => '/moments-online-shopping-day/',
  [exports.SHOP_EVENT_PRODUCT]: query =>
    `/moments-online-shopping-day/products/${query.slug}/${query.id}/`,
  [exports.BASKET]: () => '/basket/',
  [exports.CHECKOUT]: () => '/checkout/',
  [exports.CHECKOUT_SUCCESS]: () => '/checkout/success/',
  [exports.CHECKOUT_GATEWAY]: query => `/checkout/gateway/${query.paymentStatus}/`,
  [exports.DELIVERY_CONDITIONS]: () => '/moments-online-shopping-day-lieferbedingungen/',
  [exports.GIFT_CARDS_BALANCE_CHECK]: () =>
    `/geschenkkarten/${GIFT_CARDS_VIEW_MODE_DE.BALANCE}/result/`,
  [exports.GIFT_CARDS_PURCHASE_SUMMARY]: () => '/geschenkkarten/checkout/',
  [exports.GIFT_CARDS_PURCHASE_SUCCESS]: () => '/geschenkkarten/checkout/success/',
  [exports.GIFT_CARDS_PURCHASE_GATEWAY]: query => `/geschenkkarten/checkout/${query.paymentStatus}`,
  [exports.GIFT_CARDS_VIEW]: query =>
    `/geschenkkarten/${query.view || GIFT_CARDS_VIEW_MODE_DE.PURCHASE}/`,
};

exports.germanUrlsLinkGenerationRouteMap = {
  [exports.HOME]: '/',

  [exports.ACTIVATE_ACCOUNT]: '/auth/activation/',
  [exports.ACTIVATION_RESEND]: '/auth/activation/resend/',
  [exports.EMAIL_SENT]: '/auth/activation/email-sent-confirm/',
  [exports.AUTH_TERMS_AND_CONDITIONS]: '/auth/agb/',
  [exports.CREATE_PROFILE]: '/auth/customer/create/',
  [exports.FORGOT_PASSWORD]: '/auth/password/request-reset/',
  [exports.PASSWORD_RESET_CHANGE]: '/auth/password/reset/',
  [exports.PASSWORD_RESET_SENT]: '/auth/password/request-reset/confirm/',
  [exports.LOGIN]: '/auth/login/email/',
  [exports.PATIENCE]: '/auth/login/card/patience/',
  [exports.PRE_LOGIN]: '/auth/login/overview/',
  [exports.PRE_REGISTER]: '/auth/registration/overview/',
  [exports.REGISTER]: '/auth/registration/',
  [exports.REGISTER_SUCCESS]: '/auth/registration/success/',
  [exports.VIP_CARD_LOGIN]: '/auth/login/card/',
  [exports.VERIFY_ADDRESS]: '/verify-address/:token/',

  [exports.ACCOUNT_OPTIMIZATION]: '/vip/account-optimization/',
  [exports.FAVORITES]: '/vip/favorites/',
  [exports.GOODIES_DETAILS]: '/goodies/:slug/id/:id/',
  [exports.JOBS_APPLICATION_SENT]: '/arbeiten-bei-chicoree/application-sent/',
  [exports.JOBS_JOB_OFFER_DETAILS]: '/offene-stellen/:slug/id/:id/',
  [exports.JOBS_OPERATION_AREA_DETAILS]: '/arbeiten-bei-chicoree/:slug/id/:id/',
  [exports.JOBS_SPONTANEOUS_APPLICATION]: '/arbeiten-bei-chicoree/spontanbewerbung/',
  [exports.NEWSLETTER_REGISTRATION]: '/newsletter/registration/',
  [exports.NEWSLETTER_REGISTRATION_CONFIRM]: '/newsletter/registration/confirm/',
  [exports.NEWSLETTER_UNREGISTER]: '/newsletter/unregister/',
  [exports.NOT_FOUND]: '/not-found/',

  [exports.PRODUCT]: '/frauen-kleidung/:slug/id/:id/',
  [exports.PRODUCT_AVAILABILITY]: '/frauen-kleidung/:slug/id/:id/#availability/',
  [exports.PRODUCTS_CATEGORY]: '/frauen-kleidung/kategorie/:slug/id/:id/',
  [exports.PRODUCTS_NEW]: '/frauen-kleidung/neuheiten/',
  [exports.PRODUCTS_OFFER]: '/frauen-kleidung/angebote/',
  [exports.PRODUCTS_SALE]: '/frauen-kleidung/sale/',
  [exports.PRODUCTS_ALL]: '/frauen-kleidung/',
  [exports.PRODUCTS_CATCH_ALL]: '/frauen-kleidung/:slug+/',

  [exports.SHOP_EVENT]: '/moments-online-shopping-day/',
  [exports.SHOP_EVENT_PRODUCT]: '/moments-online-shopping-day/products/:slug/:id/',
  [exports.BASKET]: '/basket/',
  [exports.CHECKOUT]: '/checkout/',
  [exports.CHECKOUT_SUCCESS]: '/checkout/success/',
  [exports.CHECKOUT_GATEWAY]: '/checkout/gateway/:paymentStatus/',
  [exports.DELIVERY_CONDITIONS]: '/moments-online-shopping-day-lieferbedingungen/',

  [exports.STORE]: '/filialen/:slug/id/:store/',
  [exports.STORE_FINDER]: '/filialen/:type/',

  [exports.VIP_ACTIVITIES]: '/vip/activities/:type/',
  [exports.VIP_ACTIVITIES_ALL]: '/vip/activities/all/',
  [exports.VIP_ACTIVITIES_PURCHASES]: '/vip/activities/purchase/',
  [exports.VIP_GOODIES]: '/vip/goodies/',
  [exports.VIP_HOME]: '/vip/',
  [exports.VIP_SETTINGS]: '/vip/settings/',
  [exports.VIP_SETTINGS_ACCOUNT_DELETION]: '/vip/settings/account-deletion/',
  [exports.VIP_SETTINGS_AVATAR]: '/vip/settings/avatar/',
  [exports.VIP_SETTINGS_CHANGE_EMAIL]: '/vip/settings/change-email/',
  [exports.VIP_SETTINGS_CHANGE_EMAIL_CONFIRM]: '/vip/settings/change-email/confirm/',
  [exports.VIP_SETTINGS_CHANGE_EMAIL_TOKEN]: '/vip/settings/change-email-confirm/',
  [exports.VIP_SETTINGS_CHANGE_PASSWORD]: '/vip/settings/change-password/',
  [exports.VIP_SETTINGS_NOTIFICATIONS]: '/vip/settings/notifications/',
  [exports.VIP_SETTINGS_PROFILE_DETAILS]: '/vip/settings/profile/',
  [exports.VIP_SINGLE_PURCHASE]: '/vip/purchase/:id/',
  [exports.VIP_VOUCHERS]: '/vip/vouchers/',
  [exports.VIP_MOBILE]: '/vip/mobile/',
  [exports.VIP_INVALID_CARD]: '/auth/login/card/unavailable-for-signup/',
  [exports.VIP_CARD_NOT_YET_ACTIVATED]: '/auth/login/card/not-activated/',
  [exports.VIP_CARD_INVALID_EMAIL]: '/auth/login/card/invalid-email/',
  [exports.VIP_CARD_ALREADY_ACTIVATED]: '/auth/login/card/already-activated/',

  [exports.MOBILE_ORDER_FORM]: '/mobile/order/:plan/',
  [exports.MOBILE_ORDER_SUCCESS]: '/mobile/order/success/',
  [exports.MOBILE_PLAN_DETAILS]: '/mobile/plan/:slug/',
  [exports.MOBILE_MAIN_PAGE]: '/mobile/',

  [exports.GAME_WOF]: '/wof-splash/',
  [exports.GAME_WOF_PLAY]: '/wof-web/',
  [exports.GAME_WOF_PLAYED]: '/wof-played/',
  [exports.GAME_WOF_UNAVAILABLE]: '/wof-unavailable/',
  [exports.GAME_BAG]: '/bag-challenge-splash/',
  [exports.GAME_TROPHY_HUNT]: '/trophy-hunt-splash/',
  [exports.GAME_ADVENTCALENDAR]: '/adventcalendar-splash/',

  [exports.GIFT_CARDS_BALANCE_CHECK]: `/geschenkkarten/${GIFT_CARDS_VIEW_MODE_DE.BALANCE}/result/`,
  [exports.GIFT_CARDS_PURCHASE_SUMMARY]: '/geschenkkarten/checkout/',
  [exports.GIFT_CARDS_PURCHASE_SUCCESS]: '/geschenkkarten/checkout/success/',
  [exports.GIFT_CARDS_PURCHASE_GATEWAY]: '/geschenkkarten/checkout/gateway/:paymentStatus/',
  [exports.GIFT_CARDS_VIEW]: '/geschenkkarten/:view/',

  [exports.CMS_LOOKBOOK_PAGE]: '/lookbooks/:slug/',
  [exports.CMS_PAGE]: {
    source: '/:slug((?!_next))+/',
    pattern: '/*',
  },
};

exports.frenchUrlsRouteMap = {
  [exports.ACCOUNT_OPTIMIZATION]: () => '/vip/account-optimization/',
  [exports.ACTIVATE_ACCOUNT]: () => '/auth/activation/',
  [exports.ACTIVATION_RESEND]: () => '/auth/activation/resend/',
  [exports.PRODUCTS_ALL]: () => '/vetements-femmes/',
  [exports.PRODUCTS_CATCH_ALL]: query => `/vetements-femmes/${query.slug}`,
  [exports.AUTH_TERMS_AND_CONDITIONS]: () => '/auth/cgv/',
  [exports.CMS_LOOKBOOK_PAGE]: query => `/lookbooks/${query.slug}/`,
  [exports.CMS_PAGE]: query => `/${query.slug}/`,
  [exports.CREATE_PROFILE]: () => '/auth/customer/create/',
  [exports.EMAIL_SENT]: () => '/auth/activation/email-sent-confirm/',
  [exports.FAVORITES]: () => '/vip/favorites/',
  [exports.FORGOT_PASSWORD]: () => '/auth/password/request-reset/',
  [exports.GOODIES_DETAILS]: query => `/goodies/${query.slug}/id/${query.id}/`,
  [exports.HOME]: () => '/',
  [exports.JOBS_APPLICATION_SENT]: () => '/travailler-pour-chicoree/application-sent/',
  [exports.JOBS_JOB_OFFER_DETAILS]: query => `/postes-vacants/${query.slug}/id/${query.id}/`,
  [exports.JOBS_OPERATION_AREA_DETAILS]: query =>
    `/travailler-pour-chicoree/${query.slug}/id/${query.id}/`,
  [exports.JOBS_SPONTANEOUS_APPLICATION]: () => '/travailler-pour-chicoree/candidature-spontanee/',
  [exports.LOGIN]: () => '/auth/login/email/',
  [exports.NEWSLETTER_REGISTRATION]: () => '/newsletter/registration/',
  [exports.NEWSLETTER_REGISTRATION_CONFIRM]: () => '/newsletter/registration/confirm/',
  [exports.NEWSLETTER_UNREGISTER]: () => '/newsletter/unregister/',
  [exports.NOT_FOUND]: () => '/not-found/',
  [exports.PASSWORD_RESET_CHANGE]: () => '/auth/password/reset/',
  [exports.PASSWORD_RESET_SENT]: () => '/auth/password/request-reset/confirm/',
  [exports.PATIENCE]: () => '/auth/login/card/patience/',
  [exports.PRE_LOGIN]: () => '/auth/login/overview/',
  [exports.PRE_REGISTER]: () => '/auth/registration/overview/',
  [exports.PRODUCT]: query => `/vetements-femmes/${query.slug}/id/${query.id}/`,
  [exports.PRODUCT_AVAILABILITY]: query =>
    `/vetements-femmes/${query.slug}/id/${query.id}/#availability`,
  [exports.PRODUCTS_CATEGORY]: query => `/vetements-femmes/categorie/${query.slug}/id/${query.id}/`,
  [exports.PRODUCTS_NEW]: () => '/vetements-femmes/nouvelles/',
  [exports.PRODUCTS_OFFER]: () => '/vetements-femmes/offres/',
  [exports.PRODUCTS_SALE]: () => '/vetements-femmes/sale/',
  [exports.REGISTER]: () => '/auth/registration/',
  // TODO translate url
  [exports.REGISTER_SUCCESS]: () => '/auth/registration/success/',
  [exports.STORE]: query => `/magasins/${query.slug}/id/${query.store}/`,
  [exports.STORE_FINDER]: query => `/magasins/${query.type || 'tous'}/`,
  [exports.VERIFY_ADDRESS]: query => `/verify-address/${query.token}/`,
  [exports.VIP_ACTIVITIES]: query => `/vip/activities/${query.type}/`,
  [exports.VIP_ACTIVITIES_ALL]: () => '/vip/activities/all/',
  [exports.VIP_ACTIVITIES_PURCHASES]: () => '/vip/activities/purchase/',
  [exports.VIP_CARD_LOGIN]: () => '/auth/login/card/',
  [exports.VIP_GOODIES]: () => '/vip/goodies/',
  [exports.VIP_HOME]: () => '/vip/',
  [exports.VIP_SETTINGS]: () => '/vip/settings/',
  [exports.VIP_SETTINGS_ACCOUNT_DELETION]: () => '/vip/settings/account-deletion/',
  [exports.VIP_SETTINGS_AVATAR]: () => '/vip/settings/avatar/',
  [exports.VIP_SETTINGS_CHANGE_EMAIL]: () => '/vip/settings/change-email/',
  [exports.VIP_SETTINGS_CHANGE_EMAIL_CONFIRM]: () => '/vip/settings/change-email/confirm/',
  [exports.VIP_SETTINGS_CHANGE_EMAIL_TOKEN]: () => '/vip/settings/change-email-confirm/',
  [exports.VIP_SETTINGS_CHANGE_PASSWORD]: () => '/vip/settings/change-password/',
  [exports.VIP_SETTINGS_NOTIFICATIONS]: () => '/vip/settings/notifications/',
  [exports.VIP_SETTINGS_PROFILE_DETAILS]: () => '/vip/settings/profile/',
  [exports.VIP_SINGLE_PURCHASE]: query => `/vip/purchase/${query.id}/`,
  [exports.VIP_VOUCHERS]: () => '/vip/vouchers/',
  [exports.VIP_MOBILE]: () => '/vip/mobile/',
  [exports.VIP_INVALID_CARD]: () => '/auth/login/card/unavailable-for-signup/',
  [exports.VIP_CARD_NOT_YET_ACTIVATED]: () => '/auth/login/card/not-activated/',
  [exports.VIP_CARD_INVALID_EMAIL]: () => '/auth/login/card/invalid-email/',
  [exports.VIP_CARD_ALREADY_ACTIVATED]: () => '/auth/login/card/already-activated/',
  // TODO translate url
  [exports.MOBILE_ORDER_FORM]: query => `/mobile/order/${query.plan}/`,
  [exports.MOBILE_ORDER_SUCCESS]: () => '/mobile/order/success/',
  [exports.MOBILE_PLAN_DETAILS]: query => `/mobile/plan/${query.slug}/`,
  [exports.MOBILE_MAIN_PAGE]: () => '/mobile/',
  [exports.GAME_WOF]: () => '/wof-splash/',
  [exports.GAME_WOF_PLAY]: query => `/wof-web?token=${query.token}`,
  [exports.GAME_WOF_PLAYED]: () => '/wof-played/',
  [exports.GAME_WOF_UNAVAILABLE]: () => '/wof-unavailable/',
  [exports.GAME_BAG]: () => '/bag-challenge-splash/',
  [exports.GAME_TROPHY_HUNT]: () => '/trophy-hunt-splash/',
  [exports.GAME_ADVENTCALENDAR]: () => '/adventcalendar-splash/',
  [exports.SHOP_EVENT]: () => '/moments-online-shopping-day/',
  [exports.SHOP_EVENT_PRODUCT]: query =>
    `/moments-online-shopping-day/products/${query.slug}/${query.id}/`,
  [exports.BASKET]: () => '/panier/',
  [exports.CHECKOUT]: () => '/checkout/',
  [exports.CHECKOUT_SUCCESS]: () => '/checkout/success/',
  [exports.CHECKOUT_GATEWAY]: query => `/checkout/gateway/${query.paymentStatus}/`,
  [exports.DELIVERY_CONDITIONS]: () => '/moments-online-shopping-day-conditions-de-livraison/',
  [exports.GIFT_CARDS_BALANCE_CHECK]: () =>
    `/carte-cadeau/${GIFT_CARDS_VIEW_MODE_FR.BALANCE}/resultat/`,
  [exports.GIFT_CARDS_PURCHASE_SUMMARY]: () => '/carte-cadeau/checkout/',
  [exports.GIFT_CARDS_PURCHASE_SUCCESS]: () => '/carte-cadeau/checkout/success/',
  [exports.GIFT_CARDS_PURCHASE_GATEWAY]: query =>
    `/carte-cadeau/checkout/gateway/${query.paymentStatus}`,
  [exports.GIFT_CARDS_VIEW]: query =>
    `/carte-cadeau/${query.view || GIFT_CARDS_VIEW_MODE_FR.PURCHASE}/`,
};

exports.frenchUrlsLinkGenerationRouteMap = {
  [exports.HOME]: '/',
  [exports.ACCOUNT_OPTIMIZATION]: '/vip/account-optimization/',
  [exports.ACTIVATE_ACCOUNT]: '/auth/activation/',
  [exports.ACTIVATION_RESEND]: '/auth/activation/resend/',
  [exports.AUTH_TERMS_AND_CONDITIONS]: '/auth/cgv/',
  [exports.CREATE_PROFILE]: '/auth/customer/create/',
  [exports.EMAIL_SENT]: '/auth/activation/email-sent-confirm/',
  [exports.FAVORITES]: '/vip/favorites/',
  [exports.FORGOT_PASSWORD]: '/auth/password/request-reset/',
  [exports.GOODIES_DETAILS]: '/goodies/:slug/id/:id/',
  [exports.JOBS_APPLICATION_SENT]: '/travailler-pour-chicoree/application-sent/',
  [exports.JOBS_JOB_OFFER_DETAILS]: '/postes-vacants/:slug/id/:id/',
  [exports.JOBS_OPERATION_AREA_DETAILS]: '/travailler-pour-chicoree/:slug/id/:id/',
  [exports.JOBS_SPONTANEOUS_APPLICATION]: '/travailler-pour-chicoree/candidature-spontanee/',
  [exports.LOGIN]: '/auth/login/email/',
  [exports.NEWSLETTER_REGISTRATION]: '/newsletter/registration/',
  [exports.NEWSLETTER_REGISTRATION_CONFIRM]: '/newsletter/registration/confirm/',
  [exports.NEWSLETTER_UNREGISTER]: '/newsletter/unregister/',
  [exports.NOT_FOUND]: '/not-found/',
  [exports.PASSWORD_RESET_CHANGE]: '/auth/password/reset/',
  [exports.PASSWORD_RESET_SENT]: '/auth/password/request-reset/confirm/',
  [exports.PATIENCE]: '/auth/login/card/patience/',
  [exports.PRE_LOGIN]: '/auth/login/overview/',
  [exports.PRE_REGISTER]: '/auth/registration/overview/',
  [exports.PRODUCT]: '/vetements-femmes/:slug/id/:id/',
  [exports.PRODUCT_AVAILABILITY]: '/vetements-femmes/:slug/id/:id/#availability/',
  [exports.PRODUCTS_CATEGORY]: '/vetements-femmes/categorie/:slug/id/:id/',
  [exports.PRODUCTS_NEW]: '/vetements-femmes/nouvelles/',
  [exports.PRODUCTS_OFFER]: '/vetements-femmes/offres/',
  [exports.PRODUCTS_SALE]: '/vetements-femmes/sale/',
  [exports.PRODUCTS_ALL]: '/vetements-femmes/',
  [exports.PRODUCTS_CATCH_ALL]: '/vetements-femmes/:slug+',
  [exports.REGISTER]: '/auth/registration/',
  [exports.REGISTER_SUCCESS]: '/auth/registration/success/',
  [exports.STORE]: '/magasins/:slug/id/:store/',
  [exports.STORE_FINDER]: '/magasins/:type/',
  [exports.VERIFY_ADDRESS]: '/verify-address/:token/',
  [exports.VIP_ACTIVITIES]: '/vip/activities/:type/',
  [exports.VIP_CARD_LOGIN]: '/auth/login/card/',
  [exports.VIP_GOODIES]: '/vip/goodies/',
  [exports.VIP_HOME]: '/vip/',
  [exports.VIP_SETTINGS]: '/vip/settings/',
  [exports.VIP_SETTINGS_ACCOUNT_DELETION]: '/vip/settings/account-deletion/',
  [exports.VIP_SETTINGS_AVATAR]: '/vip/settings/avatar/',
  [exports.VIP_SETTINGS_CHANGE_EMAIL]: '/vip/settings/change-email/',
  [exports.VIP_SETTINGS_CHANGE_EMAIL_CONFIRM]: '/vip/settings/change-email/confirm/',
  [exports.VIP_SETTINGS_CHANGE_EMAIL_TOKEN]: '/vip/settings/change-email-confirm/',
  [exports.VIP_SETTINGS_CHANGE_PASSWORD]: '/vip/settings/change-password/',
  [exports.VIP_SETTINGS_NOTIFICATIONS]: '/vip/settings/notifications/',
  [exports.VIP_SETTINGS_PROFILE_DETAILS]: '/vip/settings/profile/',
  [exports.VIP_SINGLE_PURCHASE]: '/vip/purchase/:id/',
  [exports.VIP_VOUCHERS]: '/vip/vouchers/',
  [exports.VIP_MOBILE]: '/vip/mobile/',
  [exports.VIP_INVALID_CARD]: '/auth/login/card/unavailable-for-signup/',
  [exports.VIP_CARD_NOT_YET_ACTIVATED]: '/auth/login/card/not-activated/',
  [exports.VIP_CARD_INVALID_EMAIL]: '/auth/login/card/invalid-email/',
  [exports.VIP_CARD_ALREADY_ACTIVATED]: '/auth/login/card/already-activated/',
  [exports.MOBILE_ORDER_FORM]: '/mobile/order/:plan/',
  [exports.MOBILE_ORDER_SUCCESS]: '/mobile/order/success/',
  [exports.MOBILE_PLAN_DETAILS]: '/mobile/plan/:slug/',
  [exports.MOBILE_MAIN_PAGE]: '/mobile/',
  [exports.GAME_WOF]: '/wof-splash/',
  [exports.GAME_WOF_PLAY]: '/wof-web/',
  [exports.GAME_WOF_PLAYED]: '/wof-played/',
  [exports.GAME_WOF_UNAVAILABLE]: '/wof-unavailable/',
  [exports.GAME_BAG]: '/bag-challenge-splash/',
  [exports.GAME_TROPHY_HUNT]: '/trophy-hunt-splash/',
  [exports.GAME_ADVENTCALENDAR]: '/adventcalendar-splash/',
  [exports.CMS_LOOKBOOK_PAGE]: '/lookbooks/:slug/',
  [exports.SHOP_EVENT]: '/moments-online-shopping-day/',
  [exports.SHOP_EVENT_PRODUCT]: '/moments-online-shopping-day/products/:slug/:id/',
  [exports.BASKET]: '/panier/',
  [exports.CHECKOUT]: '/checkout/',
  [exports.CHECKOUT_SUCCESS]: '/checkout/success/',
  [exports.CHECKOUT_GATEWAY]: '/checkout/gateway/:paymentStatus/',
  [exports.DELIVERY_CONDITIONS]: '/moments-online-shopping-day-conditions-de-livraison/',
  [exports.GIFT_CARDS_BALANCE_CHECK]: `/carte-cadeau/${GIFT_CARDS_VIEW_MODE_FR.BALANCE}/resultat/`,
  [exports.GIFT_CARDS_PURCHASE_SUMMARY]: '/carte-cadeau/checkout/',
  [exports.GIFT_CARDS_PURCHASE_SUCCESS]: '/carte-cadeau/checkout/success/',
  [exports.GIFT_CARDS_PURCHASE_GATEWAY]: '/carte-cadeau/checkout/gateway/:paymentStatus/',
  [exports.GIFT_CARDS_VIEW]: '/carte-cadeau/:view/',

  [exports.CMS_PAGE]: {
    source: '/:slug((?!_next))+/',
    pattern: '/*',
  },
};

exports.LANGUAGE_ROUTES = [
  ['de', exports.germanUrlsLinkGenerationRouteMap],
  ['fr', exports.frenchUrlsLinkGenerationRouteMap],
];
