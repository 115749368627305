import { HYDRATE } from 'next-redux-wrapper';

import * as actions from '../actions/favorites';

const initialState = {
  hasMore: true,
  isFeatureDiscoveryVisible: false,
  isFetching: false,
  resultsAuthenticated: [],
  resultsUnauthenticated: [],
  wasFeatureDiscoveryClosed: false,
};

export default function favoritesReducer(state = initialState, { type, payload }) {
  switch (type) {
    case HYDRATE:
      return {
        ...state,
        resultsAuthenticated: payload.favorites.resultsAuthenticated,
      };

    case actions.GET_FAVORITES_REQUEST:
      return { ...state, isFetching: true };

    case actions.GET_FAVORITES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        resultsAuthenticated: [...payload].map(
          productColorIdObject => productColorIdObject.productColorId
        ),
      };

    case actions.GET_FAVORITES_FAILURE:
      return { ...state, isFetching: false };

    case actions.ADD_FAVORITE_SUCCESS:
      return {
        ...state,
        isFeatureDiscoveryVisible: !state.wasFeatureDiscoveryClosed,
        isFetching: false,
        resultsAuthenticated: payload.map(
          productColorIdObject => productColorIdObject.productColorId
        ),
      };

    case actions.UNFAVORITE_REQUEST:
      return {
        ...state,
        isUnfavoriteRequest: true,
      };

    case actions.UNFAVORITE_SUCCESS:
      return {
        ...state,
        isUnfavoriteRequest: false,
        resultsAuthenticated: state.resultsAuthenticated.filter(
          productColorId => !payload.includes(productColorId)
        ),
        resultsUnauthenticated: state.resultsUnauthenticated.filter(
          productColorId => !payload.includes(productColorId)
        ),
      };

    case actions.UNFAVORITE_FAILURE:
      return {
        ...state,
        isUnfavoriteRequest: false,
      };

    case actions.FAVORITE_UNAUTHENTICATED:
      return {
        ...state,
        isFeatureDiscoveryVisible: !state.wasFeatureDiscoveryClosed,
        resultsUnauthenticated: [...state.resultsUnauthenticated, payload],
      };

    case actions.UNFAVORITE_UNAUTHENTICATED:
      return {
        ...state,
        resultsUnauthenticated: state.resultsUnauthenticated.filter(id => !payload.includes(id)),
      };

    case actions.CLEAR_FAVORITES_UNAUTHENTICATED:
      return { ...state, resultsUnauthenticated: [] };

    case actions.CLEAR_FAVORITES_AUTHENTICATED:
      return { ...state, resultsAuthenticated: [] };

    case actions.CLOSE_FEATURE_DISCOVERY:
      return { ...state, isFeatureDiscoveryVisible: false, wasFeatureDiscoveryClosed: true };

    default:
      return state;
  }
}
