/* eslint-disable global-require */
import { HYDRATE } from 'next-redux-wrapper';

import * as actions from '@actions/basket';

import { isBrowser } from '@utils/environmentCheck';

const initialState = {
  hasProducts: false,
  productsIds: [],
  products: [],
  productsErrors: [],
  paymentMethods: [],
  calculations: {},
  order: {
    transactionId: null,
    createdAt: null,
  },
  isRecalculationNeeded: true,
  isAddToBasketToolTipVisible: false,
  isFetching: true,
  shopEventId: null,
};

export function basketReducer(state = initialState, { type, payload }) {
  switch (type) {
    case HYDRATE:
      return {
        ...state,
        paymentMethods: [...payload.basket.paymentMethods],
      };

    case actions.BASKET_ADD_PRODUCT: {
      const { product, shopEventId } = payload;
      const productsIds = [...state.productsIds];
      const products = [...state.products];
      const productIndex = state.productsIds.indexOf(product.articleNr);

      if (productIndex === -1) {
        productsIds.push(product.articleNr);
        products.push({
          ...product,
        });
      } else {
        const existingProduct = products[productIndex];

        products.splice(productIndex, 1, {
          ...existingProduct,
          quantity: existingProduct.quantity + product.quantity,
        });
      }

      return {
        ...state,
        shopEventId,
        hasProducts: !!productsIds.length,
        productsIds,
        products,
        isRecalculationNeeded: true,
      };
    }

    case actions.BASKET_REMOVE_PRODUCT: {
      const productsIds = [...state.productsIds];
      const products = [...state.products];
      const productIndex = productsIds.indexOf(payload);

      productsIds.splice(productIndex, 1);
      products.splice(productIndex, 1);

      const hasProducts = !!productsIds.length;

      return {
        ...state,
        hasProducts,
        productsIds,
        products,
        calculations: hasProducts ? state.calculations : {},
        isRecalculationNeeded: hasProducts,
      };
    }

    case actions.BASKET_UPDATE_PRODUCT: {
      const products = [...state.products];
      const productIndex = state.productsIds.indexOf(payload.articleNr);

      if (productIndex !== -1) {
        products.splice(productIndex, 1, {
          ...products[productIndex],
          quantity: payload.quantity ?? products[productIndex].quantity,
          availability: payload.availability ?? products[productIndex].availability,
        });
      }

      return {
        ...state,
        products,
        isRecalculationNeeded: true,
      };
    }

    case actions.GET_BASKET_CALCULATIONS_REQUEST:
      return {
        ...state,
        order: {
          ...initialState.order,
        },
        isFetching: true,
      };

    case actions.GET_BASKET_CALCULATIONS_SUCCESS:
      return {
        ...state,
        calculations: {
          ...payload,
          items: state.productsIds.map(id =>
            payload.items.find(({ articleNr }) => articleNr === id)),
        },
        isRecalculationNeeded: false,
        isFetching: false,
      };

    case actions.CLEAR_BASKET:
      return {
        ...state,
        ...initialState,
        paymentMethods: [...state.paymentMethods],
        isRecalculationNeeded: false,
      };

    case actions.GET_BASKET_PAYMENT_METHODS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case actions.GET_BASKET_CALCULATIONS_FAILURE:
    case actions.GET_BASKET_PAYMENT_METHODS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case actions.GET_BASKET_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        paymentMethods: [...payload],
        isFetching: false,
      };

    case actions.SUBMIT_ORDER_SUCCESS:
      return {
        ...state,
        order: {
          transactionId: payload.payment.transactionId,
          createdAt: Date.now(),
        },
      };

    case actions.SUBMIT_ORDER_FAILURE:
      return {
        ...state,
        productsErrors: payload.items,
      };

    case actions.CLEAR_BASKET_ORDER:
      return {
        ...state,
        order: {
          ...initialState.order,
        },
      };

    case actions.OPEN_ADD_TO_BASKET_TOOLTIP:
      return {
        ...state,
        isAddToBasketToolTipVisible: true,
      };

    case actions.CLOSE_ADD_TO_BASKET_TOOLTIP:
      return {
        ...state,
        isAddToBasketToolTipVisible: false,
      };

    default:
      return state;
  }
}

export default (() => {
  if (!isBrowser) {
    return basketReducer;
  }

  const { persistReducer } = require('redux-persist');
  const { default: storage } = require('redux-persist/lib/storage');

  return persistReducer(
    {
      key: 'basket',
      whitelist: ['productsIds', 'products', 'hasProducts', 'order', 'shopEventId'],
      storage,
    },
    basketReducer
  );
})();
