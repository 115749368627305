import styled, { css } from 'styled-components';

import { getThemeColor } from '@utils/styled';

import Button, { BUTTON_VARIANTS } from '@common/components/Button';

export const CrossButton = styled(Button).attrs({
  variant: BUTTON_VARIANTS.LEVEL_2_BLUEGREY_90_SMALL,
})`
  ${({ theme, isSmall }) => theme.getFluidSpacing('padding', 'equal', isSmall ? 6 : 5)};
  ${({ isWhite }) =>
    isWhite &&
    css`
      background-color: ${getThemeColor('grey.100')};
      border-color: ${getThemeColor('grey.100')};
    `}
  width: auto;
  height: auto;
  box-shadow: ${({ hasShadow, theme }) => hasShadow && theme.shadows[1]};
  border-radius: ${({ theme }) => theme.borderRadiuses.pill}rem;
  transform: ${({ isSmall }) => (isSmall ? 'scale(1.1)' : 'scale(1.5)')};

  span {
    pointer-events: none;
  }

  svg {
    transform: scale(1.5);
    pointer-events: none;
  }
`;
