import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg
      width={18}
      height={23}
      viewBox="0 0 18 23"
      {...props}
    >
      <path d="M15.8 7.7h-1.1V5.5c0-3-2.5-5.5-5.6-5.5S3.5 2.5 3.5 5.5v2.2H2.4C1.2 7.7.2 8.7.2 9.9v11c0 1.2 1 2.2 2.2 2.2h13.5c1.2 0 2.2-1 2.2-2.2v-11C18 8.7 17 7.7 15.8 7.7zM9 17.5c-1.2 0-2.2-1-2.2-2.2 0-1.2 1-2.2 2.2-2.2s2.2 1 2.2 2.2c0 1.2-1 2.2-2.2 2.2zm3.5-9.8h-7V5.5c0-1.9 1.6-3.4 3.5-3.4s3.5 1.5 3.5 3.4v2.2z" />
    </svg>
  );
}

export default SvgComponent;
