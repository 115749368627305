import styled, { css } from 'styled-components';

import { getGridBase } from '@utils/styled';

import Typography from '@common/components/Typography';
import { STICKY_HEADER_HEIGHT } from '@common/constants/ui';

export const Section = styled.div`
  ${getGridBase()};
  margin: 0 auto;
  ${({ theme, hasContent, alertHeight }) =>
    (hasContent ?
      `margin-top: calc(${STICKY_HEADER_HEIGHT}rem + ${alertHeight}px)` :
      theme.getFluidSpacing('margin-top', 'scale', 1))};

  ${({ isMobileApp }) =>
    isMobileApp &&
    css`
      margin-bottom: 10rem;
    `}
`;

export const Title = styled(Typography)`
  margin-top: 0;
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'equal', 3)};
`;
