import React from 'react';

export function SvgComponent(props) {
  return (
    <svg
      width={512}
      height={512}
      viewBox="0 0 512 512"
      {...props}
    >
      <mask id="a">
        <circle
          cx="256"
          cy="256"
          r="256"
          fill="#fff"
        />
      </mask>
      <g mask="url(#a)">
        <path
          fill="#eee"
          d="M167 0h178l25.9 252.3L345 512H167l-29.8-253.4z"
        />
        <path
          fill="#6da544"
          d="M0 0h167v512H0z"
        />
        <path
          fill="#d80027"
          d="M345 0h167v512H345z"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
