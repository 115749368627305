import getConfig from 'next/config';

import * as actions from '@actions/products';

import request from '@utils/request';

import { PRODUCTS_PER_PAGE } from '@common/constants/products';
import * as endpoints from '@common/endpoints';
import { OFFER_TYPES } from '@common/constants/filters';

const { PRODUCTS_BACKEND_URL } = getConfig().publicRuntimeConfig;

export const fetchProducts = ({ page = 1, ...params } = {}) => async dispatch => {
  dispatch(actions.getProductsRequest());

  return request
    .get(endpoints.products(), {
      baseURL: PRODUCTS_BACKEND_URL,
      params: {
        limit: PRODUCTS_PER_PAGE,
        offset: PRODUCTS_PER_PAGE * (page - 1),
        ...params,
      },
    })
    .then(({ data }) => {
      dispatch(actions.getProductsSuccess(data));
    })
    .catch(error => {
      dispatch(actions.getProductsFailure(error));
    });
};

export const fetchProductsByColorIds = (colorIds = []) => dispatch => {
  dispatch(actions.getProductsRequest(colorIds));

  return request
    .get(endpoints.products(), {
      baseURL: PRODUCTS_BACKEND_URL,
      params: {
        limit: colorIds.length,
        offset: 0,
        productColorId: colorIds.length > 0 ? colorIds.join(',') : undefined,
      },
    })
    .then(({ data }) => {
      dispatch(actions.getProductsSuccess(data));
    })
    .catch(error => {
      dispatch(actions.getProductsFailure(error));
    });
};

export const fetchProductById = id => dispatch => {
  dispatch(actions.getProductRequest(id));

  return request
    .get(endpoints.product(id), {
      baseURL: PRODUCTS_BACKEND_URL,
    })
    .then(({ data }) => {
      if (data.productId) {
        dispatch(actions.getProductSuccess(id, data));
      } else {
        const error = new Error('Not found');

        error.status = 404;

        dispatch(actions.getProductFailure(id, error));

        throw error;
      }
    })
    .catch(error => {
      dispatch(actions.getProductFailure(id, error));

      throw error;
    });
};

export const getAvailableProductsCount = (params = {}) => dispatch => {
  const offset = 0;

  dispatch(actions.getAvailableProductsCountRequest());

  return request
    .get(endpoints.products(), {
      baseURL: PRODUCTS_BACKEND_URL,
      params: {
        limit: 1,
        offset,
        ...params,
      },
    })
    .then(({ data }) => {
      dispatch(actions.getAvailableProductsCountSuccess(data));

      return data.count;
    })
    .catch(error => {
      dispatch(actions.getAvailableProductsCountFailure(error));

      return null;
    });
};

export const fetchStoreProducts = storeId => dispatch => {
  // TODO - the store_id parameter is not working on the API side
  // const offset = selectors.selectProductsListOffset(getState());

  dispatch(actions.getProductsInStoreRequest(storeId));

  return request
    .get(endpoints.products(), {
      baseURL: PRODUCTS_BACKEND_URL,
      params: {
        limit: PRODUCTS_PER_PAGE,
        // offset,
        publishedOnly: true,
        storeId,
      },
    })
    .then(({ data }) => {
      dispatch(actions.getProductsInStoreSuccess(data));
    })
    .catch(error => {
      dispatch(actions.getProductsInStoreFailure(error));
    });
};

const getParams = params => ({
  baseURL: PRODUCTS_BACKEND_URL,
  params: {
    ...params,
    published_only: true,
    limit: 1,
    offset: 0,
    ordering: '-sorting_date',
  },
});

export const fetchOfferProductsCount = dispatch => {
  dispatch(actions.getOfferProductsCountRequest());

  return request
    .get(endpoints.products(), getParams({ offer_type: OFFER_TYPES.OFFER }))
    .then(({ data }) => {
      dispatch(actions.getOfferProductsCountSuccess(data));
    })
    .catch(() => {
      dispatch(actions.getOfferProductsCountFailure());
    });
};

export const fetchSaleProductsCount = dispatch => {
  dispatch(actions.getSaleProductsCountRequest());

  return request
    .get(endpoints.products(), getParams({ offer_type: OFFER_TYPES.SALE }))
    .then(({ data }) => {
      dispatch(actions.getSaleProductsCountSuccess(data));
    })
    .catch(() => {
      dispatch(actions.getSaleProductsCountFailure());
    });
};

export const fetchProductDescription = body => dispatch => {
  dispatch(actions.getProductDescriptionRequest());

  return request
    .post(endpoints.productDescription(), body)
    .then(({ data }) => {
      dispatch(actions.getProductDescriptionSuccess({ id: body.productId, ...data }));
    })
    .catch(() => {
      dispatch(actions.getProductDescriptionFailure());
    });
};

export const fetchLatestNews = () => dispatch => {
  dispatch(actions.getLatestNewsRequest());

  return request
    .get(endpoints.cmsLatestNews())
    .then(({ data }) => {
      dispatch(actions.getLatestNewsSuccess(data));
    })
    .catch(() => {
      dispatch(actions.getLatestNewsFailure());
    });
};

export const fetchLatestLookbook = () => dispatch => {
  dispatch(actions.getLatestLookbookRequest());

  return request
    .get(endpoints.cmsLatestLookbook())
    .then(({ data }) => {
      dispatch(actions.getLatestLookbookSuccess(data));
    })
    .catch(() => {
      dispatch(actions.getLatestLookbookFailure());
    });
};
