import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg
      width="16"
      height="22"
      viewBox="0 0 16 22"
      {...props}
    >
      <path d="M14 19c0 .551-.449 1-1 1H3c-.551 0-1-.449-1-1V8c0-.551.449-1 1-1h1v3h2V7h4v3h2V7h1c.551 0 1 .449 1 1v11ZM6 4.131C6 2.956 6.898 2 8 2c1.103 0 2 .956 2 2.131V5H6v-.869ZM13 5h-1v-.869C12 1.854 10.206 0 8 0S4 1.854 4 4.131V5H3a3 3 0 0 0-3 3v11a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3Z" />
    </svg>
  );
}

export default SvgComponent;
