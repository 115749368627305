import { HYDRATE } from 'next-redux-wrapper';

import {
  GET_CUSTOMER_MOBILE_SUBSCRIPTION_REQUEST,
  GET_CUSTOMER_MOBILE_SUBSCRIPTION_SUCCESS,
  GET_CUSTOMER_MOBILE_SUBSCRIPTION_FAILURE,
} from '../actions/customerMobileSubscription';

const initialState = {
  data: {},
  error: {},
  isLoading: false,
};

export default function customerMobileSubscriptionReducer(state = initialState, action) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.customerMobileSubscription,
      };

    case GET_CUSTOMER_MOBILE_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: {},
      };

    case GET_CUSTOMER_MOBILE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload[0] || null,
      };

    case GET_CUSTOMER_MOBILE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}
