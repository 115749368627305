import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg
      width={24}
      height={16}
      viewBox="0 0 24 16"
      {...props}
    >
      <defs>
        <path
          d="M7 6a4.002 4.002 0 013.874 3H24v2H10.874a4.002 4.002 0 01-7.748 0H0V9h3.126C3.57 7.275 5.136 6 7 6zm0 2a2 2 0 100 4 2 2 0 000-4zM17-2a4.002 4.002 0 013.874 3H24v2h-3.126a4.002 4.002 0 01-7.748 0H0V1h13.126C13.57-.725 15.136-2 17-2zm0 2a2 2 0 100 4 2 2 0 000-4z"
          id="a"
        />
      </defs>
      <use
        fill="#000"
        fillRule="nonzero"
        xlinkHref="#a"
        transform="translate(0 2)"
      />
    </svg>
  );
}

export default SvgComponent;
