export const APP_STORE_ICON_MAP = {
  de: '/static/img/appstore-de.svg',
  fr: '/static/img/appstore-fr.svg',
};

export const GOOGLE_PLAY_ICON_MAP = {
  de: '/static/img/googleplay-de.svg',
  fr: '/static/img/googleplay-fr.svg',
};

export const IMAGE_PLACEHOLDER = '/static/img/profile-default.png';
export const LOGO_CHICOREE_GREEN = '/static/img/logo-chicoree-green.svg';
export const LOGO_CHICOREE_GOLD = '/static/img/logo-chicoree-gold.svg';
export const LOGO_CHICOREE_MOBILE_WHITE = '/static/img/logo-chicoree-mobile-white.svg';

export const COUPON_GIFT_VIDEO = '/static/img/coupons/coupon-open-video.mp4';
export const COUPON_GIFT_IMAGE = '/static/img/coupons/coupon-open-image.jpg';
export const COUPON_BG_PATTERN = '/static/img/coupons/coupon-bg-pattern.svg';
export const COUPON_DOT_PATTERN = '/static/img/coupons/dot-pattern.svg';
export const COUPON_VIP_BG_PATTERN = '/static/img/coupons/coupon-vip-bg-pattern.svg';

export const RAYS = '/static/img/rays.svg';
export const SAMPLE_PRODUCT = '/static/img/sample_product.png';
export const VIP_BACKGROUND_VIDEO = '/static/video/vip-background.mp4';
export const VIP_BACKGROUND_FALLBACK = '/static/img/vip-background-fallback.png';
export const VIP_CARD = '/static/img/vip-card.svg';
export const VIP_CARD_CLIPPED = '/static/img/vip-card-clipped.svg';
export const VIP_CARD_LOGIN_BACK = '/static/img/vip-card-login-back.png';
export const VIP_CARD_BACK = '/static/img/vip-card-back.svg';
export const VIP_CARD_LOGIN_FRONT = '/static/img/vip-card-login-front.png';
export const PRODUCT_CATEGORY_PLACEHOLDER = '/static/img/product-category-placeholder.jpg';
export const PRODUCT_PLACEHOLDER = '/static/img/product-placeholder.png';
export const TEASER_BLOCK_PLACEHOLDER = '/static/img/teaser-block-placeholder.png';
export const PAGE_TEASER_BLOCK_PLACEHOLDER = '/static/img/page-teaser-block-placeholder.png';
export const NO_FAVORITES_PAGE_HERO = '/static/img/no-favorites-page-hero.jpg';

export const GIFT_CARD_BACK = '/static/img/gift-card-back.svg';

export const MENU = {
  NEW: '/static/img/menu/new.jpeg',
  ALL: '/static/img/menu/all.jpeg',
  SALE: '/static/img/menu/sale.jpeg',
  OFFER: '/static/img/menu/offer.jpeg',
  CATEGORIES: {
    1: '/static/img/menu/clothing.jpeg',
    2: '/static/img/menu/accessories.jpeg',
  },
};
export const CATEGORY_COMMON_ICON = '/static/img/menu/category-common.svg';

export const WHEEL_OF_FORTUNE_IMAGE_MAP = {
  BACKGROUND: '/static/img/games/wof/wof-background.svg',
  SHADOW: '/static/img/games/wof/wof-shadow.svg',
  WHEEL: locale => `/static/img/games/wof/wof-placeholder-${locale}.svg`,
};
