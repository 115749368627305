import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg
      width={11}
      height={20}
      viewBox="0 0 11 20"
      {...props}
    >
      <path d="M.42 19.56a1.39 1.39 0 002.03 0l8.13-8.502a1.55 1.55 0 000-2.123L2.458.44a1.39 1.39 0 00-2.03 0 1.55 1.55 0 000 2.123l7.106 7.433-7.114 7.44a1.55 1.55 0 000 2.124" />
    </svg>
  );
}

export default SvgComponent;
