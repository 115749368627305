export const selectShopEventStatus = ({ shopEvent }) => shopEvent.status;
export const selectShopEventProductDetails = ({ shopEvent }) => shopEvent.productDetails;

export const selectShopEventProductDetailsColorVariation = id => ({ shopEvent }) =>
  shopEvent.productDetails?.colorVariations?.find(({ productColorId }) => productColorId === id);

export const selectShopEventProducts = ids => ({ shopEvent }) =>
  (ids !== undefined ?
    shopEvent.products.filter(product => ids.includes(product.productColorId)) :
    shopEvent.products);

export const selectShopEventVariantsWithAvailableSizes = ({ shopEvent }) =>
  shopEvent.productDetails?.colorVariations?.map(colorVariant => {
    const sizeObject = colorVariant?.sizes?.find(({ availability }) => availability > 0);

    return {
      colorVariant,
      sizeObject,
    };
  });

export const selectShopEventProductsAvailableSizes = id => ({ shopEvent }) => {
  const colorVariant = shopEvent.productDetails?.colorVariations?.find(
    ({ productColorId }) => productColorId === id
  );
  const sizeObject = colorVariant?.sizes?.find(({ availability }) => availability > 0);

  return {
    colorVariant,
    sizeObject,
  };
};
