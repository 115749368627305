import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';

import { isBrowser } from '@utils/environmentCheck';

import {
  BODY_RESIZING_CLASS,
  IS_SCROLLING_DOWN_CLASS,
  IS_SCROLLING_UP_CLASS,
} from '@common/constants/body';

const removeResizingClass = debounce(() => {
  document.body.classList.remove(BODY_RESIZING_CLASS);
}, 220);

const handleWindowResize = throttle(() => {
  document.body.classList.add(BODY_RESIZING_CLASS);
  removeResizingClass();
}, 200);

let previousScrollPos = isBrowser ? window.pageYOffset : 0;
const handleScroll = throttle(() => {
  const pageYOffset = window?.pageYOffset ?? 0;
  const scrolledUp = previousScrollPos > pageYOffset;
  const isBelowThreshold = pageYOffset <= 60;

  document.body.classList.toggle(IS_SCROLLING_DOWN_CLASS, !(scrolledUp || isBelowThreshold));
  document.body.classList.toggle(IS_SCROLLING_UP_CLASS, scrolledUp && !isBelowThreshold);
  previousScrollPos = pageYOffset;
}, 100);

const Body = ({ children }) => {
  useEffect(() => {
    window.addEventListener('resize', handleWindowResize, {
      capture: false,
      passive: true,
    });

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, {
      capture: false,
      passive: true,
    });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return <React.Fragment>{children}</React.Fragment>;
};

Body.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Body;
