import {
  ADD_GIFT_CARD,
  REMOVE_GIFT_CARD,
  UPDATE_GIFT_CARD_PERSONALIZATION,
  SET_GIFT_CARDS_ORDER_ADDRESS,
  SET_GIFT_CARDS_ORDER_DIRECT_DELIVERY,
  CLEAR_GIFT_CARDS_ORDER,
} from '@actions/giftCards';

const initialState = {
  address: {
    shippingAddress: {},
    invoiceAddress: {},
    isSameAddressForInvoice: true,
  },
  directDelivery: null,
  giftCards: {},
  total: 0,
};

export default function giftCardsOrderReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ADD_GIFT_CARD: {
      const { id, value } = payload;

      if (typeof value === 'undefined' || !Number.isInteger(value)) {
        throw new TypeError('The gift card needs to have an integer value!');
      }

      return {
        ...state,
        giftCards: {
          ...state.giftCards,
          [id]: {
            personalization: {
              to: '',
              message: '',
              from: '',
            },
            value,
          },
        },
        total: state.total + value,
      };
    }

    case REMOVE_GIFT_CARD: {
      const { id } = payload;
      const {
        [id]: { value: removedValue },
        ...newGiftCards
      } = state.giftCards;

      return {
        ...state,
        giftCards: newGiftCards,
        total: state.total - removedValue,
      };
    }

    case UPDATE_GIFT_CARD_PERSONALIZATION: {
      const { id, personalization } = payload;

      return {
        ...state,
        giftCards: {
          ...state.giftCards,
          [id]: {
            ...state.giftCards[id],
            personalization: {
              ...personalization,
            },
          },
        },
      };
    }

    case SET_GIFT_CARDS_ORDER_ADDRESS: {
      return {
        ...state,
        address: {
          ...state.address,
          ...payload,
          shippingAddress: {
            ...state.address.shippingAddress,
            ...payload.shippingAddress,
          },
          invoiceAddress: {
            ...state.address.invoiceAddress,
            ...payload.invoiceAddress,
          },
        },
      };
    }

    case SET_GIFT_CARDS_ORDER_DIRECT_DELIVERY: {
      return {
        ...state,
        directDelivery: payload,
      };
    }

    case CLEAR_GIFT_CARDS_ORDER: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
}
