export const GET_CUSTOMER_SHOPS_REQUEST = 'GET_CUSTOMER_SHOPS_REQUEST';
export const GET_CUSTOMER_SHOPS_SUCCESS = 'GET_CUSTOMER_SHOPS_SUCCESS';
export const GET_CUSTOMER_SHOPS_FAILURE = 'GET_CUSTOMER_SHOPS_FAILURE';

export const getCustomerShopsRequest = () => ({
  type: GET_CUSTOMER_SHOPS_REQUEST,
});

export const getCustomerShopsSuccess = ids => ({
  ids,
  type: GET_CUSTOMER_SHOPS_SUCCESS,
});

export const getCustomerShopsFailure = ids => ({
  ids,
  type: GET_CUSTOMER_SHOPS_FAILURE,
});

export const GET_PRODUCT_AVAILABILITY_REQUEST = 'GET_PRODUCT_AVAILABILITY_REQUEST';
export const GET_PRODUCT_AVAILABILITY_SUCCESS = 'GET_PRODUCT_AVAILABILITY_SUCCESS';
export const GET_PRODUCT_AVAILABILITY_FAILURE = 'GET_PRODUCT_AVAILABILITY_FAILURE';

export const getProductAvailabilityRequest = productColorId => ({
  productColorId,
  type: GET_PRODUCT_AVAILABILITY_REQUEST,
});

export const getProductAvailabilitySuccess = (availability, productColorId) => ({
  payload: {
    availability,
    productColorId,
  },
  type: GET_PRODUCT_AVAILABILITY_SUCCESS,
});

export const getProductAvailabilityFailure = (availability, productColorId) => ({
  payload: {
    availability,
    productColorId,
  },
  type: GET_PRODUCT_AVAILABILITY_FAILURE,
});

export const GET_SHOPS_REQUEST = 'GET_SHOPS_REQUEST';
export const GET_SHOPS_SUCCESS = 'GET_SHOPS_SUCCESS';
export const GET_SHOPS_FAILURE = 'GET_SHOPS_FAILURE';

export const getShopsRequest = () => ({
  type: GET_SHOPS_REQUEST,
});

export const getShopsSuccess = shops => ({
  payload: shops,
  type: GET_SHOPS_SUCCESS,
});

export const getShopsFailure = shops => ({
  payload: shops,
  type: GET_SHOPS_FAILURE,
});

export const GET_SHOP_REQUEST = 'GET_SHOP_REQUEST';
export const GET_SHOP_SUCCESS = 'GET_SHOP_SUCCESS';
export const GET_SHOP_FAILURE = 'GET_SHOP_FAILURE';

export const getShopRequest = id => ({
  id,
  type: GET_SHOP_REQUEST,
});

export const getShopSuccess = shop => ({
  shop,
  type: GET_SHOP_SUCCESS,
});

export const getShopFailure = error => ({
  error,
  type: GET_SHOP_FAILURE,
});
