import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg
      viewBox="0 0 20 20"
      width={20}
      height={20}
      {...props}
    >
      <defs>
        <path
          id="prefix__z"
          d="M11 0v2a8 8 0 017 7h2v2h-2a8 8 0 01-7 7v2H9v-2a8 8 0 01-7-7H0V9h2a8 8 0 017-7V0h2zm0 4v2H9V4a6 6 0 00-5 5h2v2H4a6 6 0 005 5v-2h2v2a6 6 0 005-5h-2V9h2a6 6 0 00-5-5z"
        />
      </defs>
      <use xlinkHref="#prefix__z" />
    </svg>
  );
}

export default SvgComponent;
