import { HYDRATE } from 'next-redux-wrapper';
import isArray from 'lodash/isArray';
import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import { normalize, schema } from 'normalizr';

import { LAYOUT_GRID } from '@common/constants/ui';
import {
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_IN_STORE_REQUEST,
  GET_PRODUCTS_FAILURE,
  GET_PRODUCTS_IN_STORE_FAILURE,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_IN_STORE_SUCCESS,
  GET_PRODUCT_SUCCESS,
  SET_LAYOUT_PREFERENCE,
  GET_OFFER_PRODUCTS_COUNT_SUCCESS,
  GET_SALE_PRODUCTS_COUNT_SUCCESS,
  GET_PRODUCT_DESCRIPTION_SUCCESS,
  GET_LATEST_NEWS_SUCCESS,
  GET_LATEST_LOOKBOOK_SUCCESS,
} from '../actions/products';

const initialState = {
  entities: {
    colorVariations: {},
    products: {},
    sizes: {},
  },
  result: [],
  count: 0,
  isFetching: false,
  hasMore: false,
  layoutPreference: LAYOUT_GRID,
  productsCount: {
    offerProductsCount: null,
    saleProductsCount: null,
  },
  latestNews: {},
  latestLookbook: {},
};

const sizeSchema = new schema.Entity('sizes', {}, { idAttribute: 'articleNr' });

const colorVariationSchema = new schema.Array(
  new schema.Entity(
    'colorVariations',
    {
      sizes: [sizeSchema],
    },
    { idAttribute: 'productColorId' }
  )
);

const productSchema = new schema.Entity(
  'products',
  {
    colorVariations: colorVariationSchema,
  },
  { idAttribute: 'productId' }
);

function mergeStrategy(objValue, srcValue) {
  if (isArray(objValue)) {
    return objValue.concat(srcValue);
  }

  return undefined;
}

export default function productsReducer(state = initialState, action) {
  switch (action.type) {
    case HYDRATE: {
      const payloadProductsCount = action.payload.products.productsCount;

      const shouldUpdateOfferCount =
        !payloadProductsCount?.offerProductsCount && payloadProductsCount?.offerProductsCount !== 0;

      const shouldUpdateSaleCount =
        !payloadProductsCount?.saleProductsCount && payloadProductsCount?.saleProductsCount !== 0;

      return {
        ...action.payload.products,
        layoutPreference: state.layoutPreference,
        productsCount: {
          offerProductsCount: shouldUpdateOfferCount ?
            state.productsCount.offerProductsCount :
            payloadProductsCount.offerProductsCount,
          saleProductsCount: shouldUpdateSaleCount ?
            state.productsCount.saleProductsCount :
            payloadProductsCount.saleProductsCount,
        },
      };
    }

    case GET_PRODUCTS_REQUEST:
    case GET_PRODUCTS_IN_STORE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case GET_PRODUCTS_FAILURE:
    case GET_PRODUCTS_IN_STORE_FAILURE:
      return {
        ...state,
        hasMore: false,
        isFetching: false,
      };

    case GET_PRODUCTS_SUCCESS:
    case GET_PRODUCTS_IN_STORE_SUCCESS:
      return mergeWith(
        {},
        {
          ...state,
          count: action.payload.count,
          hasMore: Boolean(action.payload.next),
          hasPrevious: Boolean(action.payload.previous),
          isFetching: false,
        },
        normalize(action.payload.results, colorVariationSchema),
        mergeStrategy
      );

    case GET_PRODUCT_SUCCESS: {
      const { title } = action.payload;
      const normalized = normalize(action.payload, productSchema);
      const colorVariations = Object.keys(normalized.entities.colorVariations).reduce(
        (acc, key) => {
          acc[key] = {
            ...normalized.entities.colorVariations[key],
            title,
          };

          return acc;
        },
        {}
      );

      const sizes = Object.values(normalized.entities.sizes).reduce((acc, key) => {
        acc[key.articleNr] = {
          ...key,
          sanitizedArticleNr: key.articleNr.replace(/\//g, '-'),
        };

        return acc;
      }, {});

      return merge({}, state, {
        entities: {
          ...normalized.entities,
          colorVariations,
          sizes,
        },
      });
    }

    case SET_LAYOUT_PREFERENCE:
      return {
        ...state,
        layoutPreference: action.layoutPreference,
      };

    case GET_OFFER_PRODUCTS_COUNT_SUCCESS: {
      return {
        ...state,
        productsCount: {
          ...state.productsCount,
          offerProductsCount: action.payload.count,
        },
      };
    }

    case GET_SALE_PRODUCTS_COUNT_SUCCESS: {
      return {
        ...state,
        productsCount: {
          ...state.productsCount,
          saleProductsCount: action.payload.count,
        },
      };
    }
    case GET_PRODUCT_DESCRIPTION_SUCCESS: {
      const { id, description } = action.payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          products: {
            ...state.entities.products,
            [id]: {
              ...state.entities.products[id],
              description,
            },
          },
        },
      };
    }
    case GET_LATEST_NEWS_SUCCESS: {
      return {
        ...state,
        latestNews: action.payload,
      };
    }
    case GET_LATEST_LOOKBOOK_SUCCESS: {
      // get all productColorIds from lookbook content
      const productColorIds = action.payload.body?.[0]?.value?.items
        ?.map(topItem => topItem?.items?.map(item => item?.productColorId))
        ?.flat();

      return {
        ...state,
        latestLookbook: {
          ...action.payload,
          // remove duplicates
          productColorIds: Array.from(new Set(productColorIds)),
        },
      };
    }

    default:
      return state;
  }
}
