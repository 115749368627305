import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      {...props}
    >
      <path d="M7 .5c3.7 0 6.5 3 6.5 6.7 0 1.4-.4 2.7-1.1 3.8l5.1 5.3-1.2 1.2-5.1-5.2A6.4 6.4 0 017 14C3.4 14 .5 11 .5 7.3S3.4.5 7 .5zm0 1.7a5 5 0 00-4.8 5c0 2.8 2.1 5 4.8 5a5 5 0 004.8-5c0-2.8-2.1-5-4.8-5z" />
    </svg>
  );
}

export default SvgComponent;
