export const GET_FAVORITES_REQUEST = 'GET_FAVORITES_REQUEST';
export const GET_FAVORITES_SUCCESS = 'GET_FAVORITES_SUCCESS';
export const GET_FAVORITES_FAILURE = 'GET_FAVORITES_FAILURE';

export const getFavoritesRequest = () => ({
  type: GET_FAVORITES_REQUEST,
});

export const getFavoritesSuccess = ids => ({
  payload: ids,
  type: GET_FAVORITES_SUCCESS,
});

export const getFavoritesFailure = ids => ({
  payload: ids,
  type: GET_FAVORITES_FAILURE,
});

export const ADD_FAVORITE_REQUEST = 'ADD_FAVORITE_REQUEST';
export const ADD_FAVORITE_SUCCESS = 'ADD_FAVORITE_SUCCESS';
export const ADD_FAVORITE_FAILURE = 'ADD_FAVORITE_FAILURE';

export const addFavoriteRequest = ids => ({
  payload: ids,
  type: ADD_FAVORITE_REQUEST,
});

export const addFavoriteSuccess = ids => ({
  payload: ids,
  type: ADD_FAVORITE_SUCCESS,
});

export const addFavoriteFailure = ids => ({
  payload: ids,
  type: ADD_FAVORITE_FAILURE,
});

export const UNFAVORITE_REQUEST = 'UNFAVORITE_REQUEST';
export const UNFAVORITE_SUCCESS = 'UNFAVORITE_SUCCESS';
export const UNFAVORITE_FAILURE = 'UNFAVORITE_FAILURE';

export const unfavoriteRequest = ids => ({
  payload: ids,
  type: UNFAVORITE_REQUEST,
});

export const unfavoriteSuccess = ids => ({
  payload: ids,
  type: UNFAVORITE_SUCCESS,
});

export const unfavoriteFailure = ids => ({
  payload: ids,
  type: UNFAVORITE_FAILURE,
});

export const FAVORITE_UNAUTHENTICATED = 'FAVORITE_UNAUTHENTICATED';
export const favoriteUnauthenticated = id => ({
  payload: id,
  type: FAVORITE_UNAUTHENTICATED,
});

export const UNFAVORITE_UNAUTHENTICATED = 'UNFAVORITE_UNAUTHENTICATED';
export const unfavoriteUnauthenticated = ids => ({
  payload: Array.isArray(ids) ? ids : [ids],
  type: UNFAVORITE_UNAUTHENTICATED,
});

export const CLEAR_FAVORITES_UNAUTHENTICATED = 'CLEAR_FAVORITES_UNAUTHENTICATED';
export const clearFavoritesUnauthenticated = () => ({
  type: CLEAR_FAVORITES_UNAUTHENTICATED,
});

export const CLEAR_FAVORITES_AUTHENTICATED = 'CLEAR_FAVORITES_AUTHENTICATED';
export const clearFavoritesAuthenticated = () => ({
  type: CLEAR_FAVORITES_AUTHENTICATED,
});

export const CLOSE_FEATURE_DISCOVERY = 'CLOSE_FEATURE_DISCOVERY';
export const closeFeatureDiscovery = () => ({
  type: CLOSE_FEATURE_DISCOVERY,
});
