import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg
      width={22}
      height={22}
      viewBox="0 0 22 22"
      {...props}
    >
      <path d="M11,0 C17.05,0 22,4.95 22,11 C22,17.05 17.05,22 11,22 C4.95,22 0,17.05 0,11 C0,4.95 4.95,0 11,0 Z M11,1.65 C5.83,1.65 1.65,5.83 1.65,11 C1.65,16.17 5.83,20.35 11,20.35 C16.17,20.35 20.35,16.17 20.35,11 C20.35,5.83 16.17,1.65 11,1.65 Z M12.1,3.3 L12.1,10.56 L16.17,14.63 L14.63,16.17 L9.9,11.44 L9.9,3.3 L12.1,3.3 Z" />
    </svg>
  );
}

export default SvgComponent;
