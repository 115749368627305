import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      {...props}
    >
      <defs>
        <rect
          id="prefix__column_layout"
          width={20}
          height={20}
          x={0}
          y={0}
          rx={1.5}
        />
      </defs>
      <use xlinkHref="#prefix__column_layout" />
    </svg>
  );
}

export default SvgComponent;
