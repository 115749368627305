import PropTypes from 'prop-types';
import React from 'react';

import { BUTTON_VARIANTS } from './common';
import { ButtonContentWrapper, StyledAnchor } from './Button.styled';

export const ButtonLink = React.forwardRef(
  ({
    href, children, iconLeft, iconRight, variant, isExternal, ...props
  }, ref) => (
    <StyledAnchor
      variant={variant}
      href={href}
      ref={ref}
      isExternal={isExternal}
      {...props}
    >
      <ButtonContentWrapper>
        {iconLeft}
        {children}
        {iconRight}
      </ButtonContentWrapper>
    </StyledAnchor>
  )
);

ButtonLink.propTypes = {
  children: PropTypes.string.isRequired,
  href: PropTypes.string,
  iconLeft: PropTypes.node,
  iconRight: PropTypes.node,
  variant: PropTypes.oneOf(Object.values(BUTTON_VARIANTS)),
  isExternal: PropTypes.bool,
};

ButtonLink.defaultProps = {
  href: undefined,
  iconLeft: undefined,
  iconRight: undefined,
  variant: BUTTON_VARIANTS.LEVEL_1_GREEN,
  isExternal: false,
};

export default ButtonLink;
