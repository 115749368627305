export const GET_WHEEL_OF_FORTUNE_CONFIG_REQUEST = 'GET_WHEEL_OF_FORTUNE_CONFIG_REQUEST';
export const GET_WHEEL_OF_FORTUNE_CONFIG_SUCCESS = 'GET_WHEEL_OF_FORTUNE_CONFIG_SUCCESS';
export const GET_WHEEL_OF_FORTUNE_CONFIG_FAILURE = 'GET_WHEEL_OF_FORTUNE_CONFIG_FAILURE';

export const getWheelOfFortuneConfigRequest = () => ({
  type: GET_WHEEL_OF_FORTUNE_CONFIG_REQUEST,
});

export const getWheelOfFortuneConfigSuccess = payload => ({
  payload,
  type: GET_WHEEL_OF_FORTUNE_CONFIG_SUCCESS,
});

export const getWheelOfFortuneConfigFailure = payload => ({
  payload,
  type: GET_WHEEL_OF_FORTUNE_CONFIG_FAILURE,
});

export const GET_WHEEL_OF_FORTUNE_CONFIG_WEB_TOKEN_REQUEST =
  'GET_WHEEL_OF_FORTUNE_CONFIG_WEB_TOKEN_REQUEST';
export const GET_WHEEL_OF_FORTUNE_CONFIG_WEB_TOKEN_SUCCESS =
  'GET_WHEEL_OF_FORTUNE_CONFIG_WEB_TOKEN_SUCCESS';
export const GET_WHEEL_OF_FORTUNE_CONFIG_WEB_TOKEN_FAILURE =
  'GET_WHEEL_OF_FORTUNE_CONFIG_WEB_TOKEN_FAILURE';

export const getWheelOfFortuneConfigWebTokenRequest = () => ({
  type: GET_WHEEL_OF_FORTUNE_CONFIG_WEB_TOKEN_REQUEST,
});

export const getWheelOfFortuneConfigWebTokenSuccess = payload => ({
  payload,
  type: GET_WHEEL_OF_FORTUNE_CONFIG_WEB_TOKEN_SUCCESS,
});

export const getWheelOfFortuneConfigWebTokenFailure = payload => ({
  payload,
  type: GET_WHEEL_OF_FORTUNE_CONFIG_WEB_TOKEN_FAILURE,
});
