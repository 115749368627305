import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg
      width={51}
      height={27}
      viewBox="0 0 51 27"
      {...props}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        fillRule="nonzero"
        d="M40.0215264 0c0 1 .8982388 1.9 1.8962818 1.9.9980431 0 1.8962818-.8 1.8962818-1.9h4.1917808C49.6027397 0 51 1.4 51 3v21c0 1.7-1.297456 3-2.9941292 3H43.81409c0-1-.8982387-1.9-1.8962818-1.9-.998043 0-1.8962818.8-1.8962818 1.9H2.99412916C1.29745597 27 0 25.7 0 24V3c0-1.7 1.29745597-3 2.99412916-3Zm1.6966732 18.4c-.998043 0-1.8962818.9-1.8962818 1.9 0 1 .7984345 1.9 1.8962818 1.9 1.1976517 0 1.8962818-.9 1.8962818-1.9 0-1-.8982387-1.9-1.8962818-1.9ZM10.171 7.59c-3.04 0-5.776 2.304-5.776 5.92s2.64 5.904 5.808 5.904c3.184 0 4.816-2.112 5.248-3.856l-2.064-.624c-.304 1.056-1.248 2.32-3.184 2.32-1.824 0-3.52-1.328-3.52-3.744 0-2.576 1.792-3.792 3.488-3.792 1.968 0 2.848 1.2 3.12 2.288l2.08-.656c-.448-1.84-2.064-3.76-5.2-3.76Zm8.828.24h-2.208v11.344h2.208V14.47h4.944v4.704h2.224V7.83h-2.224v4.56h-4.944V7.83Zm16.428 0h-7.168v11.344h2.224v-4.416h4.4v-2.032h-4.416v-2.8h4.96V7.83Zm6.2911996 3.87c-.998043 0-1.8962818.8-1.8962818 1.9 0 1 .7984345 1.9 1.8962818 1.9 1.1976517 0 1.9960861-.9 1.8962818-1.9 0-1-.8982387-1.9-1.8962818-1.9Zm0-6.8c-.998043 0-1.8962818.8-1.8962818 1.9 0 1 .7984345 1.9 1.8962818 1.9 1.1976517 0 1.9960861-.8 1.8962818-1.9 0-1-.8982387-1.9-1.8962818-1.9Z"
      />
    </svg>
  );
}

export default SvgComponent;
