/* eslint-disable global-require */
/* eslint-disable no-underscore-dangle */
import { applyMiddleware, createStore } from 'redux';
import { createWrapper } from 'next-redux-wrapper';
import thunkMiddleware from 'redux-thunk';
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';

import reducers from '@reducers';
import {
  BASKET_ADD_PRODUCT,
  BASKET_REMOVE_PRODUCT,
  BASKET_UPDATE_PRODUCT,
  CLEAR_BASKET,
  GET_BASKET_CALCULATIONS_REQUEST,
  GET_BASKET_CALCULATIONS_SUCCESS,
  GET_BASKET_CALCULATIONS_FAILURE,
  SUBMIT_ORDER_SUCCESS,
} from '@actions/basket';
import {
  ADD_FAVORITE_SUCCESS,
  FAVORITE_UNAUTHENTICATED,
  UNFAVORITE_SUCCESS,
  UNFAVORITE_UNAUTHENTICATED,
} from '@actions/favorites';

import { isBrowser } from '@utils/environmentCheck';

const bindMiddleware = middleware => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension');

    const composeEnhancers = composeWithDevTools({ trace: true });

    return composeEnhancers(applyMiddleware(...middleware));
  }

  return applyMiddleware(...middleware);
};

const makeConfiguredStore = (reducer, ...middlewares) =>
  createStore(reducer, bindMiddleware([thunkMiddleware, ...middlewares]));

const makeStore = () => {
  if (!isBrowser) {
    return makeConfiguredStore(reducers);
  }

  // we need it only on client side
  const { persistStore, persistReducer } = require('redux-persist');
  const storage = require('redux-persist/lib/storage').default;

  const persistConfig = {
    key: 'nextjs',
    whitelist: ['favorites'],
    storage,
  };

  const persistedReducer = persistReducer(persistConfig, reducers);
  const store = makeConfiguredStore(
    persistedReducer,
    createStateSyncMiddleware({
      channel: `chicoree_${window.location.host}`,
      whitelist: [
        BASKET_ADD_PRODUCT,
        BASKET_REMOVE_PRODUCT,
        BASKET_UPDATE_PRODUCT,
        CLEAR_BASKET,
        GET_BASKET_CALCULATIONS_REQUEST,
        GET_BASKET_CALCULATIONS_SUCCESS,
        GET_BASKET_CALCULATIONS_FAILURE,
        SUBMIT_ORDER_SUCCESS,
        UNFAVORITE_SUCCESS,
        ADD_FAVORITE_SUCCESS,
        FAVORITE_UNAUTHENTICATED,
        UNFAVORITE_UNAUTHENTICATED,
      ],
    })
  );

  store.__persistor = persistStore(store);

  initMessageListener(store);

  return store;
};

export const wrapper = createWrapper(makeStore, { debug: false });
