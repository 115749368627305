import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg
      width={30}
      height={50}
      viewBox="0 0 30 50"
      {...props}
    >
      <path d="M23.5714286,0 C27.1218305,0 30,2.79822031 30,6.25 L30,43.75 C30,47.2017797 27.1218305,50 23.5714286,50 L6.42857143,50 C2.87816947,50 0,47.2017797 0,43.75 L0,6.25 C0,2.79822031 2.87816947,0 6.42857143,0 L23.5714286,0 Z M22.8198479,3.13642862 L22.4460117,3.12694608 C22.1970583,4.3146432 21.1165916,5.20833333 19.8214286,5.20833333 L10.1785714,5.20833333 C8.88340841,5.20833333 7.8029417,4.3146432 7.55398825,3.12694608 L7.5,3.125 C5.2406533,3.125 3.38964617,4.8247454 3.22604086,6.98070345 L3.21428571,7.29166667 L3.21428571,42.7083333 C3.21428571,44.9049204 4.96259527,46.7045107 7.18015212,46.8635714 L7.5,46.875 L22.5,46.875 C24.7593467,46.875 26.6103538,45.1752546 26.7739591,43.0192966 L26.7857143,42.7083333 L26.7857143,7.29166667 C26.7857143,5.09507959 25.0374047,3.29548933 22.8198479,3.13642862 Z" />
    </svg>
  );
}

export default SvgComponent;
