import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg
      width={22}
      height={20}
      viewBox="0 0 22 20"
      {...props}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M15.931 0C19.345 0 22 2.625 22 6c0 3.754-3.141 6.99-7.995 11.31l-1.488 1.315L11 20l-1.517-1.375-1.488-1.314C3.14 12.99 0 9.754 0 6c0-3.375 2.655-6 6.069-6C7.966 0 9.862.875 11 2.25 12.264.875 14.034 0 15.931 0z"
      />
    </svg>
  );
}

export default SvgComponent;
