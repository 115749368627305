import {
  SUBMIT_GIFT_CARDS_ORDER_SUCCESS,
  SUBMIT_GIFT_CARDS_ORDER_REQUEST,
  ADD_GIFT_CARD,
  REMOVE_GIFT_CARD,
  UPDATE_GIFT_CARD_PERSONALIZATION,
  SET_GIFT_CARDS_ORDER_ADDRESS,
  CLEAR_GIFT_CARDS_ORDER,
  CLEAR_GIFT_CARDS_PAYMENT,
} from '@actions/giftCards';

const initialState = {
  transactionId: null,
  createdAt: null,
};

export default function giftCardsPaymentReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SUBMIT_GIFT_CARDS_ORDER_SUCCESS: {
      return {
        ...state,
        transactionId: payload.payment.transactionId,
        createdAt: Date.now(),
      };
    }

    case ADD_GIFT_CARD:
    case REMOVE_GIFT_CARD:
    case UPDATE_GIFT_CARD_PERSONALIZATION:
    case SET_GIFT_CARDS_ORDER_ADDRESS:
    case SUBMIT_GIFT_CARDS_ORDER_REQUEST:
    case CLEAR_GIFT_CARDS_ORDER:
    case CLEAR_GIFT_CARDS_PAYMENT: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
}
