import React, {
  createContext, useCallback, useContext, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

import { isBrowser } from '@utils/environmentCheck';

const HistoryLengthContext = createContext(0);

HistoryLengthContext.displayName = 'HistoryLengthContext';

const PATH_REGEXP = '(?<path>/[^?]+)(?<query>\\?.+)?';

export function HistoryLengthWrapper({ children }) {
  const router = useRouter();
  const scrollPositions = useRef({});
  const isGoingBack = useRef(false);

  const isScrollRestorationEnabled = useRef(true);
  const disableScrollRestoration = useCallback(() => {
    isScrollRestorationEnabled.current = false;
  }, [isScrollRestorationEnabled]);

  const [history, setHistory] = useState({
    previousUrl: undefined,
    length: 0,
    disableScrollRestoration,
  });

  useEffect(() => {
    const handleRouteChange = url => {
      scrollPositions.current[`/${router.locale}${router.asPath}`] = window.scrollY;

      if (isGoingBack.current) {
        return;
      }

      const { groups: { path: newPath } = {} } =
        url.match(new RegExp(`/${router.locale}${PATH_REGEXP}`)) || {};
      const { groups: { path: oldPath } = {} } = router.asPath.match(new RegExp(PATH_REGEXP)) || {};

      if (newPath === oldPath) {
        return;
      }

      isScrollRestorationEnabled.current = true;

      setHistory(prevHistory => ({
        ...prevHistory,
        length: !newPath ? 0 : prevHistory.length + 1,
      }));
    };

    const handlePopstate = () => {
      isGoingBack.current = true;
      isScrollRestorationEnabled.current = true;

      setHistory(prevHistory => ({
        ...prevHistory,
        previousUrl: router.asPath,
        length: prevHistory.length - 1,
      }));
    };

    const handleRouteChangeComplete = url => {
      if (
        isGoingBack.current &&
        isScrollRestorationEnabled.current &&
        scrollPositions.current[url]
      ) {
        window.scroll({
          top: scrollPositions.current[url],
          behavior: 'auto',
        });
      }

      isGoingBack.current = false;
    };

    router.events.on('beforeHistoryChange', handleRouteChange);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);
    window.addEventListener('popstate', handlePopstate);

    return () => {
      router.events.off('beforeHistoryChange', handleRouteChange);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
      window.removeEventListener('popstate', handlePopstate);
    };
  }, [router]);

  useEffect(() => {
    if (isBrowser && window.history.scrollRestoration) {
      window.history.scrollRestoration = 'manual';
    }
  }, []);

  return <HistoryLengthContext.Provider value={history}>{children}</HistoryLengthContext.Provider>;
}

HistoryLengthWrapper.propTypes = {
  children: PropTypes.element.isRequired,
};

export function useHistoryLengthContext() {
  return useContext(HistoryLengthContext);
}
