import * as actions from '../actions/filters';

const initialState = {
  filtersOptions: null,
  initialFilterOptions: null,
  isFetchingOptions: false,
};

export default function filtersReducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_FILTERS_OPTIONS_REQUEST:
      return { ...state, isFetchingOptions: true };
    case actions.FETCH_FILTERS_OPTIONS_SUCCESS:
      return { ...state, filtersOptions: action.payload, isFetchingOptions: false };
    case actions.FETCH_FILTERS_OPTIONS_FAILURE:
      return { ...state, filtersOptions: initialState.filters, isFetchingOptions: false };
    case actions.SET_INITIAL_FILTER_OPTIONS:
      return { ...state, initialFilterOptions: action.payload };
    case actions.SET_FILTER_COLORS:
      return {
        ...state,
        filtersOptions: {
          ...state.filtersOptions,
          colorFilters: action.payload,
        },
        isFetchingOptions: false,
      };
    case actions.SET_FILTER_SIZES:
      return {
        ...state,
        filtersOptions: {
          ...state.filtersOptions,
          sizeFilters: action.payload,
        },
        isFetchingOptions: false,
      };
    default:
      return state;
  }
}
