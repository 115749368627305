import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg
      width={14}
      height={24}
      viewBox="0 0 14 24"
      {...props}
    >
      <path d="M12.2 24L0 12 12.2 0 14 1.8 3.6 12 14 22.2z" />
    </svg>
  );
}

export default SvgComponent;
