export const GET_SHOP_EVENT_STATUS_REQUEST = 'GET_SHOP_EVENT_STATUS_REQUEST';
export const GET_SHOP_EVENT_STATUS_SUCCESS = 'GET_SHOP_EVENT_STATUS_SUCCESS';
export const GET_SHOP_EVENT_STATUS_FAILURE = 'GET_SHOP_EVENT_STATUS_FAILURE';

export const getShopEventStatusRequest = () => ({
  type: GET_SHOP_EVENT_STATUS_REQUEST,
});

export const getShopEventStatusSuccess = payload => ({
  payload,
  type: GET_SHOP_EVENT_STATUS_SUCCESS,
});

export const getShopEventStatusFailure = payload => ({
  payload,
  type: GET_SHOP_EVENT_STATUS_FAILURE,
});

export const GET_SHOP_EVENT_PRODUCT_DETAILS_REQUEST = 'GET_SHOP_EVENT_PRODUCT_DETAILS_REQUEST';
export const GET_SHOP_EVENT_PRODUCT_DETAILS_SUCCESS = 'GET_SHOP_EVENT_PRODUCT_DETAILS_SUCCESS';
export const GET_SHOP_EVENT_PRODUCT_DETAILS_FAILURE = 'GET_SHOP_EVENT_PRODUCT_DETAILS_FAILURE';

export const getShopEventProductDetailsRequest = () => ({
  type: GET_SHOP_EVENT_PRODUCT_DETAILS_REQUEST,
});

export const getShopEventProductDetailsSuccess = payload => ({
  payload,
  type: GET_SHOP_EVENT_PRODUCT_DETAILS_SUCCESS,
});

export const getShopEventProductDetailsFailure = () => ({
  type: GET_SHOP_EVENT_PRODUCT_DETAILS_FAILURE,
});

export const GET_SHOP_EVENT_PRODUCTS_REQUEST = 'GET_SHOP_EVENT_PRODUCTS_REQUEST';
export const GET_SHOP_EVENT_PRODUCTS_SUCCESS = 'GET_SHOP_EVENT_PRODUCTS_SUCCESS';
export const GET_SHOP_EVENT_PRODUCTS_FAILURE = 'GET_SHOP_EVENT_PRODUCTS_FAILURE';

export const getShopEventProductsRequest = () => ({
  type: GET_SHOP_EVENT_PRODUCTS_REQUEST,
});

export const getShopEventProductsSuccess = payload => ({
  payload,
  type: GET_SHOP_EVENT_PRODUCTS_SUCCESS,
});

export const getShopEventProductsFailure = payload => ({
  payload,
  type: GET_SHOP_EVENT_PRODUCTS_FAILURE,
});

export const GET_SHOP_EVENT_PRODUCT_DESCRIPTION_REQUEST = 'GET_SHOP_EVENT_PRODUCT_DESCRIPTION';
export const GET_SHOP_EVENT_PRODUCT_DESCRIPTION_SUCCESS =
  'GET_SHOP_EVENT_PRODUCT_DESCRIPTION_SUCCESS';
export const GET_SHOP_EVENT_PRODUCT_DESCRIPTION_FAILURE =
  'GET_SHOP_EVENT_PRODUCT_DESCRIPTION_FAILURE';

export const getShopEventProductDescriptionRequest = () => ({
  type: GET_SHOP_EVENT_PRODUCT_DESCRIPTION_REQUEST,
});

export const getShopEventProductDescriptionFailure = () => ({
  type: GET_SHOP_EVENT_PRODUCT_DESCRIPTION_FAILURE,
});

export const getShopEventProductDescriptionSuccess = payload => ({
  payload,
  type: GET_SHOP_EVENT_PRODUCT_DESCRIPTION_SUCCESS,
});
